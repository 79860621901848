/* ../../../../../../tmp/tmp-6713-TAUgn4ceTQ9q/Source/src/css/app.css */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tDMPKhSkFEkm8.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tMMPKhSkFEkm8.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE0tCMPKhSkFE.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1pVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21lVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucXtAOvWDSHFF.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLufntAOvWDSHFF.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLucHtAOvWDSA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19VF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15VF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111VF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVFteOYktMqlap.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVGdeOYktMqlap.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xVF9eOYktMqg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucXtAOvWDSHFF.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTufntAOvWDSHFF.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtAOvWDSA.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJbecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJnecnFHGPezSQ.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecnFHGPc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z11lFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1JlFd2JQEl8qw.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJPax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJLax8s3JimW3w.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwTs572Xtc6ZYQws9YVwnNDTJzax8s3Jik.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNJfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/publicsans/v14/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
html body {
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Public Sans;
  color: #475569;
  line-height: 1.25rem;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

::before,
::after {
  --tw-content: "";
}

html,
:host {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  line-height: inherit;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
input:where([type=button]),
input:where([type=reset]),
input:where([type=submit]) {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role=button] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[type=text],
input:where(:not([type])),
[type=email],
[type=url],
[type=password],
[type=number],
[type=date],
[type=datetime-local],
[type=month],
[type=search],
[type=tel],
[type=time],
[type=week],
[multiple],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

[type=text]:focus,
input:where(:not([type])):focus,
[type=email]:focus,
[type=url]:focus,
[type=password]:focus,
[type=number]:focus,
[type=date]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=week]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit,
::-webkit-datetime-edit-year-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute-field,
::-webkit-datetime-edit-second-field,
::-webkit-datetime-edit-millisecond-field,
::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

[multiple],
[size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}

[type=checkbox],
[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type=checkbox] {
  border-radius: 0px;
}

[type=radio] {
  border-radius: 100%;
}

[type=checkbox]:focus,
[type=radio]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type=checkbox]:checked,
[type=radio]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type=checkbox]:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type=radio]:checked {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
[type=checkbox]:checked:hover,
[type=checkbox]:checked:focus,
[type=radio]:checked:hover,
[type=radio]:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type=checkbox]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (forced-colors: active) {
  [type=checkbox]:indeterminate {
    -webkit-appearance: auto;
    -moz-appearance: auto;
    appearance: auto;
  }
}
[type=checkbox]:indeterminate:hover,
[type=checkbox]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type=file] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type=file]:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

:root {
  --color-theme-1: 70 90 101;
  --color-theme-2: 63 162 141;
  --color-primary: 68 176 153;
  --color-secondary: 63 162 141;
  --color-success: 94 193 172;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
  --neutral-black: rgb(64,155,137);
}

.dark {
  --color-primary: 29 78 216;
  --color-darkmode-50: 87 103 132;
  --color-darkmode-100: 74 90 121;
  --color-darkmode-200: 65 81 114;
  --color-darkmode-300: 53 69 103;
  --color-darkmode-400: 48 61 93;
  --color-darkmode-500: 41 53 82;
  --color-darkmode-600: 40 51 78;
  --color-darkmode-700: 35 45 69;
  --color-darkmode-800: 27 37 59;
  --color-darkmode-900: 15 23 42;
}

.theme-1 {
  --color-theme-1: 76 29 149;
  --color-theme-2: 159 18 57;
  --color-primary: 76 29 149;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-1.dark {
  --color-primary: 7 89 133;
}

.theme-2 {
  --color-theme-1: 88 28 135;
  --color-theme-2: 14 116 144;
  --color-primary: 88 28 135;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-2.dark {
  --color-primary: 7 89 133;
}

.theme-3 {
  --color-theme-1: 14 116 144;
  --color-theme-2: 91 33 182;
  --color-primary: 14 116 144;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-3.dark {
  --color-primary: 7 89 133;
}

.theme-4 {
  --color-theme-1: 3 105 161;
  --color-theme-2: 159 18 57;
  --color-primary: 3 105 161;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-4.dark {
  --color-primary: 7 89 133;
}

.theme-5 {
  --color-theme-1: 7 89 133;
  --color-theme-2: 6 95 70;
  --color-primary: 7 89 133;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-5.dark {
  --color-primary: 7 89 133;
}

.theme-6 {
  --color-theme-1: 36 123 160;
  --color-theme-2: 10 36 99;
  --color-primary: 36 123 160;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-6.dark {
  --color-primary: 7 89 133;
}

.theme-7 {
  --color-theme-1: 26 46 5;
  --color-theme-2: 19 78 74;
  --color-primary: 26 46 5;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-7.dark {
  --color-primary: 7 89 133;
}

.theme-8 {
  --color-theme-1: 53 114 102;
  --color-theme-2: 14 59 67;
  --color-primary: 53 114 102;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-8.dark {
  --color-primary: 7 89 133;
}

.theme-9 {
  --color-theme-1: 108 108 96;
  --color-theme-2: 77 77 66;
  --color-primary: 108 108 96;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-9.dark {
  --color-primary: 7 89 133;
}

.theme-10 {
  --color-theme-1: 55 48 163;
  --color-theme-2: 30 58 138;
  --color-primary: 55 48 163;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-10.dark {
  --color-primary: 7 89 133;
}

.theme-11 {
  --color-theme-1: 47 62 70;
  --color-theme-2: 82 121 111;
  --color-primary: 47 62 70;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-11.dark {
  --color-primary: 7 89 133;
}

.theme-12 {
  --color-theme-1: 94 80 63;
  --color-theme-2: 34 51 59;
  --color-primary: 94 80 63;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-12.dark {
  --color-primary: 7 89 133;
}

.theme-13 {
  --color-theme-1: 94 84 142;
  --color-theme-2: 35 25 66;
  --color-primary: 94 84 142;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-13.dark {
  --color-primary: 7 89 133;
}

.theme-14 {
  --color-theme-1: 2 41 47;
  --color-theme-2: 118 117 34;
  --color-primary: 2 41 47;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-14.dark {
  --color-primary: 7 89 133;
}

.theme-15 {
  --color-theme-1: 76 149 108;
  --color-theme-2: 0 100 102;
  --color-primary: 76 149 108;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-15.dark {
  --color-primary: 7 89 133;
}

.theme-16 {
  --color-theme-1: 12 74 110;
  --color-theme-2: 23 37 84;
  --color-primary: 12 74 110;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-16.dark {
  --color-primary: 7 89 133;
}

.theme-17 {
  --color-theme-1: 15 23 42;
  --color-theme-2: 30 41 59;
  --color-primary: 15 23 42;
  --color-secondary: 226 232 240;
  --color-success: 13 148 136;
  --color-info: 8 145 178;
  --color-warning: 202 138 4;
  --color-pending: 194 65 12;
  --color-danger: 185 28 28;
  --color-light: 241 245 249;
  --color-dark: 30 41 59;
}

.theme-17.dark {
  --color-primary: 7 89 133;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1320px) {
  .container {
    max-width: 1320px;
  }
}
.\!box {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.0431372549) !important;
  background-color: white !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 0.6rem !important;
  position: relative !important;
}

.box {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.0431372549);
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  position: relative;
}

.box--stacked:before {
  content: "";
  z-index: -1;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  height: 100%;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  position: absolute;
  border-radius: 0.5rem;
  left: 0.875rem;
  right: 0.875rem;
  top: 0;
  margin-top: 0.75rem;
}

.dark .box {
  background-color: rgb(var(--color-darkmode-600)/1);
  border-color: rgb(var(--color-darkmode-500)/1);
}

.dark .\!box {
  background-color: rgb(var(--color-darkmode-600)/1) !important;
  border-color: rgb(var(--color-darkmode-500)/1) !important;
}

.dark .box--stacked:before {
  background-color: rgb(var(--color-darkmode-600)/70%);
  border-color: rgba(100, 116, 139, 0.6);
}

.image-fit {
  position: relative;
}

.image-fit > img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  top: 0;
  width: 100%;
  height: 100%;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0px;
  background-color: transparent;
}

@keyframes type-animation {
  50% {
    opacity: 1;
  }
}
.zoom-in {
  transition-property: transform, box-shadow;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.zoom-in:hover {
  transform: scale(1.05);
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0px;
}

.inset-x-0 {
  left: 0px;
  right: 0px;
}

.inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}

.right-auto {
  right: auto;
}

.top-0 {
  top: 0px;
}

.top-\[104px\] {
  top: 104px;
}

.top-\[6\.2rem\] {
  top: 6.2rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.z-\[-1\] {
  z-index: -1;
}

.z-\[60\] {
  z-index: 60;
}

.z-\[9999\] {
  z-index: 9999;
}

.order-first {
  order: -9999;
}

.col-span-12 {
  grid-column: span 12/span 12;
}

.col-span-2 {
  grid-column: span 2/span 2;
}

.col-span-3 {
  grid-column: span 3/span 3;
}

.col-span-4 {
  grid-column: span 4/span 4;
}

.col-span-6 {
  grid-column: span 6/span 6;
}

.col-span-7 {
  grid-column: span 7/span 7;
}

.row-start-1 {
  grid-row-start: 1;
}

.float-left {
  float: left;
}

.-m-px {
  margin: -1px;
}

.m-2 {
  margin: 0.5rem;
}

.m-2\.5 {
  margin: 0.625rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-auto {
  margin: auto;
}

.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.\!mt-1 {
  margin-top: 0.25rem !important;
}

.\!mt-5 {
  margin-top: 1.25rem !important;
}

.-mb-1 {
  margin-bottom: -0.25rem;
}

.-mb-1\.5 {
  margin-bottom: -0.375rem;
}

.-mb-3 {
  margin-bottom: -0.75rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-0 {
  margin-left: 0px;
}

.-ml-0\.5 {
  margin-left: -0.125rem;
}

.-ml-1 {
  margin-left: -0.25rem;
}

.-ml-1\.5 {
  margin-left: -0.375rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-ml-2 {
  margin-left: -0.5rem;
}

.-ml-2\.5 {
  margin-left: -0.625rem;
}

.-ml-3 {
  margin-left: -0.75rem;
}

.-ml-3\.5 {
  margin-left: -0.875rem;
}

.-ml-36 {
  margin-left: -9rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-ml-52 {
  margin-left: -13rem;
}

.-ml-\[275px\] {
  margin-left: -275px;
}

.-ml-\[60px\] {
  margin-left: -60px;
}

.-mr-0 {
  margin-right: 0px;
}

.-mr-0\.5 {
  margin-right: -0.125rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.-mr-1\.5 {
  margin-right: -0.375rem;
}

.-mr-2 {
  margin-right: -0.5rem;
}

.-mr-2\.5 {
  margin-right: -0.625rem;
}

.-mr-36 {
  margin-right: -9rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mr-52 {
  margin-right: -13rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mr-\[100\%\] {
  margin-right: -100%;
}

.-mr-\[13\%\] {
  margin-right: -13%;
}

.-mr-\[23\%\] {
  margin-right: -23%;
}

.-mt-0 {
  margin-top: 0px;
}

.-mt-0\.5 {
  margin-top: -0.125rem;
}

.-mt-1 {
  margin-top: -0.25rem;
}

.-mt-1\.5 {
  margin-top: -0.375rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mt-2 {
  margin-top: -0.5rem;
}

.-mt-2\.5 {
  margin-top: -0.625rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-3 {
  margin-top: -0.75rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mt-\[13rem\] {
  margin-top: -13rem;
}

.-mt-\[5\.5rem\] {
  margin-top: -5.5rem;
}

.-mt-px {
  margin-top: -1px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mb-0\.5 {
  margin-bottom: 0.125rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mb-\[0\.4rem\] {
  margin-bottom: 0.4rem;
}

.mb-\[18px\] {
  margin-bottom: 18px;
}

.ml-0 {
  margin-left: 0px;
}

.ml-0\.5 {
  margin-left: 0.125rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-1\.5 {
  margin-left: 0.375rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-16 {
  margin-left: 4rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-2\.5 {
  margin-left: 0.625rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-3\.5 {
  margin-left: 0.875rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[275px\] {
  margin-left: 275px;
}

.ml-auto {
  margin-left: auto;
}

.ml-px {
  margin-left: 1px;
}

.mr-0 {
  margin-right: 0px;
}

.mr-0\.5 {
  margin-right: 0.125rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mr-auto {
  margin-right: auto;
}

.mr-px {
  margin-right: 1px;
}

.mt-0 {
  margin-top: 0px;
}

.mt-0\.5 {
  margin-top: 0.125rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-1\.5 {
  margin-top: 0.375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-3\.5 {
  margin-top: 0.875rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mt-\[55px\] {
  margin-top: 55px;
}

.mt-\[65px\] {
  margin-top: 65px;
}

.mt-\[75px\] {
  margin-top: 75px;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.\!h-0 {
  height: 0px !important;
}

.h-0 {
  height: 0px;
}

.h-1 {
  height: 0.25rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: 0.5rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: 0.75rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-3\/4 {
  height: 75%;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[1\.15rem\] {
  height: 1.15rem;
}

.h-\[1\.2rem\] {
  height: 1.2rem;
}

.h-\[100\%\] {
  height: 100%;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[101px\] {
  height: 101px;
}

.h-\[120\%\] {
  height: 120%;
}

.h-\[121px\] {
  height: 121px;
}

.h-\[140\%\] {
  height: 140%;
}

.h-\[15\%\] {
  height: 15%;
}

.h-\[15px\] {
  height: 15px;
}

.h-\[16px\] {
  height: 16px;
}

.h-\[17px\] {
  height: 17px;
}

.h-\[18px\] {
  height: 18px;
}

.h-\[190px\] {
  height: 190px;
}

.h-\[2\.5rem\] {
  height: 2.5rem;
}

.h-\[20\%\] {
  height: 20%;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[216px\] {
  height: 216px;
}

.h-\[220px\] {
  height: 220px;
}

.h-\[22px\] {
  height: 22px;
}

.h-\[23px\] {
  height: 23px;
}

.h-\[244px\] {
  height: 244px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[25\%\] {
  height: 25%;
}

.h-\[26px\] {
  height: 26px;
}

.h-\[280px\] {
  height: 280px;
}

.h-\[30\%\] {
  height: 30%;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[317px\] {
  height: 317px;
}

.h-\[34px\] {
  height: 34px;
}

.h-\[35\%\] {
  height: 35%;
}

.h-\[35px\] {
  height: 35px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[4\.5rem\] {
  height: 4.5rem;
}

.h-\[40\%\] {
  height: 40%;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[45\%\] {
  height: 45%;
}

.h-\[45px\] {
  height: 45px;
}

.h-\[46\.6rem\] {
  height: 46.6rem;
}

.h-\[50\%\] {
  height: 50%;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[55px\] {
  height: 55px;
}

.h-\[65\%\] {
  height: 65%;
}

.h-\[65px\] {
  height: 65px;
}

.h-\[70\%\] {
  height: 70%;
}

.h-\[72px\] {
  height: 72px;
}

.h-\[75\%\] {
  height: 75%;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[87px\] {
  height: 87px;
}

.h-\[90\%\] {
  height: 90%;
}

.h-\[97\%\] {
  height: 97%;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-\[468px\] {
  max-height: 468px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1 {
  width: 0.25rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: 0.5rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-2\/4 {
  width: 50%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: 0.75rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[1\.15rem\] {
  width: 1.15rem;
}

.w-\[15px\] {
  width: 15px;
}

.w-\[16px\] {
  width: 16px;
}

.w-\[17px\] {
  width: 17px;
}

.w-\[18px\] {
  width: 18px;
}

.w-\[19rem\] {
  width: 19rem;
}

.w-\[2\.5rem\] {
  width: 2.5rem;
}

.w-\[20\%\] {
  width: 20%;
}

.w-\[20px\] {
  width: 20px;
}

.w-\[21\%\] {
  width: 21%;
}

.w-\[22px\] {
  width: 22px;
}

.w-\[23px\] {
  width: 23px;
}

.w-\[26px\] {
  width: 26px;
}

.w-\[275px\] {
  width: 275px;
}

.w-\[3\.8rem\] {
  width: 3.8rem;
}

.w-\[33\%\] {
  width: 33%;
}

.w-\[34px\] {
  width: 34px;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[350px\] {
  width: 350px;
}

.w-\[36px\] {
  width: 36px;
}

.w-\[38px\] {
  width: 38px;
}

.w-\[4\.5rem\] {
  width: 4.5rem;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[40px\] {
  width: 40px;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[45px\] {
  width: 45px;
}

.w-\[5\%\] {
  width: 5%;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[54px\] {
  width: 54px;
}

.w-\[55\%\] {
  width: 55%;
}

.w-\[55px\] {
  width: 55px;
}

.w-\[60\%\] {
  width: 60%;
}

.w-\[65\%\] {
  width: 65%;
}

.w-\[66\%\] {
  width: 66%;
}

.w-\[72px\] {
  width: 72px;
}

.w-\[75\%\] {
  width: 75%;
}

.w-\[90\%\] {
  width: 90%;
}

.w-\[95\%\] {
  width: 95%;
}

.w-\[97\%\] {
  width: 97%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-\[1\.15rem\] {
  min-width: 1.15rem;
}

.min-w-\[420px\] {
  min-width: 420px;
}

.min-w-\[6rem\] {
  min-width: 6rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-\[11rem\] {
  max-width: 11rem;
}

.max-w-\[12rem\] {
  max-width: 12rem;
}

.max-w-\[15rem\] {
  max-width: 15rem;
}

.max-w-\[16rem\] {
  max-width: 16rem;
}

.max-w-\[17rem\] {
  max-width: 17rem;
}

.max-w-\[3\.8rem\] {
  max-width: 3.8rem;
}

.max-w-\[6rem\] {
  max-width: 6rem;
}

.max-w-\[7rem\] {
  max-width: 7rem;
}

.max-w-\[85\%\] {
  max-width: 85%;
}

.max-w-\[8rem\] {
  max-width: 8rem;
}

.max-w-\[9rem\] {
  max-width: 9rem;
}

.max-w-none {
  max-width: none;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-none {
  flex: none;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-y-\[10px\] {
  --tw-border-spacing-y: 10px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: 0.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-12 {
  --tw-rotate: -12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-\[9deg\] {
  --tw-rotate: -9deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-12 {
  --tw-rotate: 12deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-10deg\] {
  --tw-rotate: -10deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[135deg\] {
  --tw-rotate: 135deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.animate-spin {
  animation: spin 1s linear infinite;
}

.cursor-move {
  cursor: move;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-text {
  cursor: text;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-decimal {
  list-style-type: decimal;
}

.list-disc {
  list-style-type: disc;
}

.appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-center {
  place-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.gap-0 {
  gap: 0px;
}

.gap-0\.5 {
  gap: 0.125rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-3\.5 {
  gap: 0.875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-56 {
  gap: 14rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-7 {
  gap: 1.75rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[0\.4rem\] {
  gap: 0.4rem;
}

.gap-x-1 {
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

.gap-x-1\.5 {
  -moz-column-gap: 0.375rem;
  column-gap: 0.375rem;
}

.gap-x-2 {
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

.gap-x-3 {
  -moz-column-gap: 0.75rem;
  column-gap: 0.75rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-x-5 {
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.gap-x-6 {
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-x-\[0\.4rem\] {
  -moz-column-gap: 0.4rem;
  column-gap: 0.4rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-y-1\.5 {
  row-gap: 0.375rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-y-3\.5 {
  row-gap: 0.875rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.gap-y-7 {
  row-gap: 1.75rem;
}

.gap-y-8 {
  row-gap: 2rem;
}

.gap-y-9 {
  row-gap: 2.25rem;
}

.gap-y-\[0\.4rem\] {
  row-gap: 0.4rem;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-\[0\.17rem\] {
  border-radius: 0.17rem;
}

.rounded-\[0\.3rem\] {
  border-radius: 0.3rem;
}

.rounded-\[0\.4rem\] {
  border-radius: 0.4rem;
}

.rounded-\[0\.5rem\] {
  border-radius: 0.5rem;
}

.rounded-\[0\.6rem\] {
  border-radius: 0.6rem;
}

.rounded-\[0\.75rem_0_0_0\.75rem\/1\.1rem_0_0_1\.1rem\] {
  border-radius: 0.75rem 0 0 0.75rem/1.1rem 0 0 1.1rem;
}

.rounded-\[0\.8rem\] {
  border-radius: 0.8rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}

.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-\[1\.5px\] {
  border-width: 1.5px;
}

.border-\[3px\] {
  border-width: 3px;
}

.border-\[6px\] {
  border-width: 6px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-y-0 {
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-\[\#0077b5\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 119 181/var(--tw-border-opacity));
}

.border-\[\#3b5998\] {
  --tw-border-opacity: 1;
  border-color: rgb(59 89 152/var(--tw-border-opacity));
}

.border-\[\#4ab3f4\] {
  --tw-border-opacity: 1;
  border-color: rgb(74 179 244/var(--tw-border-opacity));
}

.border-\[\#517fa4\] {
  --tw-border-opacity: 1;
  border-color: rgb(81 127 164/var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14/var(--tw-border-opacity));
}

.border-amber-800\/50 {
  border-color: rgba(146, 64, 14, 0.5);
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175/var(--tw-border-opacity));
}

.border-blue-800\/50 {
  border-color: rgba(30, 64, 175, 0.5);
}

.border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117/var(--tw-border-opacity));
}

.border-cyan-800\/50 {
  border-color: rgba(21, 94, 117, 0.5);
}

.border-danger {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-danger)/var(--tw-border-opacity));
}

.border-danger\/10 {
  border-color: rgb(var(--color-danger)/0.1);
}

.border-danger\/40 {
  border-color: rgb(var(--color-danger)/0.4);
}

.border-danger\/50 {
  border-color: rgb(var(--color-danger)/0.5);
}

.border-danger\/60 {
  border-color: rgb(var(--color-danger)/0.6);
}

.border-dark {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-dark)/var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52/var(--tw-border-opacity));
}

.border-green-800\/50 {
  border-color: rgba(22, 101, 52, 0.5);
}

.border-info\/10 {
  border-color: rgb(var(--color-info)/0.1);
}

.border-info\/50 {
  border-color: rgb(var(--color-info)/0.5);
}

.border-info\/60 {
  border-color: rgb(var(--color-info)/0.6);
}

.border-orange-800 {
  --tw-border-opacity: 1;
  border-color: rgb(154 52 18/var(--tw-border-opacity));
}

.border-orange-800\/50 {
  border-color: rgba(154, 52, 18, 0.5);
}

.border-pending {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-pending)/var(--tw-border-opacity));
}

.border-pending\/10 {
  border-color: rgb(var(--color-pending)/0.1);
}

.border-pending\/20 {
  border-color: rgb(var(--color-pending)/0.2);
}

.border-pending\/50 {
  border-color: rgb(var(--color-pending)/0.5);
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.border-primary\/10 {
  border-color: rgb(var(--color-primary)/0.1);
}

.border-primary\/20 {
  border-color: rgb(var(--color-primary)/0.2);
}

.border-primary\/30 {
  border-color: rgb(var(--color-primary)/0.3);
}

.border-primary\/40 {
  border-color: rgb(var(--color-primary)/0.4);
}

.border-primary\/5 {
  border-color: rgb(var(--color-primary)/0.05);
}

.border-primary\/50 {
  border-color: rgb(var(--color-primary)/0.5);
}

.border-primary\/60 {
  border-color: rgb(var(--color-primary)/0.6);
}

.border-primary\/70 {
  border-color: rgb(var(--color-primary)/0.7);
}

.border-primary\/80 {
  border-color: rgb(var(--color-primary)/0.8);
}

.border-primary\/\[0\.15\] {
  border-color: rgb(var(--color-primary)/0.15);
}

.border-primary\/\[0\.25\] {
  border-color: rgb(var(--color-primary)/0.25);
}

.border-primary\/\[0\.35\] {
  border-color: rgb(var(--color-primary)/0.35);
}

.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgb(153 27 27/var(--tw-border-opacity));
}

.border-red-800\/50 {
  border-color: rgba(153, 27, 27, 0.5);
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-secondary)/var(--tw-border-opacity));
}

.border-secondary\/70 {
  border-color: rgb(var(--color-secondary)/0.7);
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249/var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240/var(--tw-border-opacity));
}

.border-slate-200\/40 {
  border-color: rgba(226, 232, 240, 0.4);
}

.border-slate-200\/50 {
  border-color: rgba(226, 232, 240, 0.5);
}

.border-slate-200\/60 {
  border-color: rgba(226, 232, 240, 0.6);
}

.border-slate-200\/70 {
  border-color: rgba(226, 232, 240, 0.7);
}

.border-slate-200\/80 {
  border-color: rgba(226, 232, 240, 0.8);
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225/var(--tw-border-opacity));
}

.border-slate-300\/50 {
  border-color: rgba(203, 213, 225, 0.5);
}

.border-slate-300\/60 {
  border-color: rgba(203, 213, 225, 0.6);
}

.border-slate-300\/70 {
  border-color: rgba(203, 213, 225, 0.7);
}

.border-slate-300\/80 {
  border-color: rgba(203, 213, 225, 0.8);
}

.border-slate-400\/20 {
  border-color: rgba(148, 163, 184, 0.2);
}

.border-slate-400\/60 {
  border-color: rgba(148, 163, 184, 0.6);
}

.border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252/var(--tw-border-opacity));
}

.border-slate-500\/60 {
  border-color: rgba(100, 116, 139, 0.6);
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105/var(--tw-border-opacity));
}

.border-slate-600\/40 {
  border-color: rgba(71, 85, 105, 0.4);
}

.border-success {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-success)/var(--tw-border-opacity));
}

.border-success\/10 {
  border-color: rgb(var(--color-success)/0.1);
}

.border-success\/40 {
  border-color: rgb(var(--color-success)/0.4);
}

.border-success\/50 {
  border-color: rgb(var(--color-success)/0.5);
}

.border-success\/60 {
  border-color: rgb(var(--color-success)/0.6);
}

.border-teal-800 {
  --tw-border-opacity: 1;
  border-color: rgb(17 94 89/var(--tw-border-opacity));
}

.border-teal-800\/50 {
  border-color: rgba(17, 94, 89, 0.5);
}

.border-theme-1 {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-theme-1)/var(--tw-border-opacity));
}

.border-theme-1\/10 {
  border-color: rgb(var(--color-theme-1)/0.1);
}

.border-theme-1\/20 {
  border-color: rgb(var(--color-theme-1)/0.2);
}

.border-theme-1\/30 {
  border-color: rgb(var(--color-theme-1)/0.3);
}

.border-theme-1\/40 {
  border-color: rgb(var(--color-theme-1)/0.4);
}

.border-theme-1\/50 {
  border-color: rgb(var(--color-theme-1)/0.5);
}

.border-theme-1\/60 {
  border-color: rgb(var(--color-theme-1)/0.6);
}

.border-theme-1\/\[0\.13\] {
  border-color: rgb(var(--color-theme-1)/0.13);
}

.border-theme-2\/40 {
  border-color: rgb(var(--color-theme-2)/0.4);
}

.border-transparent {
  border-color: transparent;
}

.border-warning {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-warning)/var(--tw-border-opacity));
}

.border-warning\/10 {
  border-color: rgb(var(--color-warning)/0.1);
}

.border-warning\/30 {
  border-color: rgb(var(--color-warning)/0.3);
}

.border-warning\/60 {
  border-color: rgb(var(--color-warning)/0.6);
}

.border-warning\/\[0\.15\] {
  border-color: rgb(var(--color-warning)/0.15);
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255/var(--tw-border-opacity));
}

.border-white\/10 {
  border-color: rgba(255, 255, 255, 0.1);
}

.border-white\/20 {
  border-color: rgba(255, 255, 255, 0.2);
}

.border-white\/40 {
  border-color: rgba(255, 255, 255, 0.4);
}

.border-white\/50 {
  border-color: rgba(255, 255, 255, 0.5);
}

.border-white\/70 {
  border-color: rgba(255, 255, 255, 0.7);
}

.border-white\/90 {
  border-color: rgba(255, 255, 255, 0.9);
}

.border-white\/\[0\.13\] {
  border-color: rgba(255, 255, 255, 0.13);
}

.border-white\/\[0\.15\] {
  border-color: rgba(255, 255, 255, 0.15);
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgb(133 77 14/var(--tw-border-opacity));
}

.border-yellow-800\/50 {
  border-color: rgba(133, 77, 14, 0.5);
}

.border-t-slate-200\/60 {
  border-top-color: rgba(226, 232, 240, 0.6);
}

.border-opacity-5 {
  --tw-border-opacity: 0.05;
}

.bg-\[\#0077b5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 119 181/var(--tw-bg-opacity));
}

.bg-\[\#3b5998\] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 89 152/var(--tw-bg-opacity));
}

.bg-\[\#4ab3f4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(74 179 244/var(--tw-bg-opacity));
}

.bg-\[\#517fa4\] {
  --tw-bg-opacity: 1;
  background-color: rgb(81 127 164/var(--tw-bg-opacity));
}

.bg-amber-800\/40 {
  background-color: rgba(146, 64, 14, 0.4);
}

.bg-amber-800\/80 {
  background-color: rgba(146, 64, 14, 0.8);
}

.bg-blue-800\/40 {
  background-color: rgba(30, 64, 175, 0.4);
}

.bg-blue-800\/80 {
  background-color: rgba(30, 64, 175, 0.8);
}

.bg-cyan-800\/40 {
  background-color: rgba(21, 94, 117, 0.4);
}

.bg-cyan-800\/80 {
  background-color: rgba(21, 94, 117, 0.8);
}

.bg-danger {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-danger)/var(--tw-bg-opacity));
}

.bg-danger\/10 {
  background-color: rgb(var(--color-danger)/0.1);
}

.bg-danger\/5 {
  background-color: rgb(var(--color-danger)/0.05);
}

.bg-danger\/60 {
  background-color: rgb(var(--color-danger)/0.6);
}

.bg-danger\/70 {
  background-color: rgb(var(--color-danger)/0.7);
}

.bg-danger\/80 {
  background-color: rgb(var(--color-danger)/0.8);
}

.bg-danger\/\[0\.15\] {
  background-color: rgb(var(--color-danger)/0.15);
}

.bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-dark)/var(--tw-bg-opacity));
}

.bg-green-800\/40 {
  background-color: rgba(22, 101, 52, 0.4);
}

.bg-green-800\/80 {
  background-color: rgba(22, 101, 52, 0.8);
}

.bg-info\/10 {
  background-color: rgb(var(--color-info)/0.1);
}

.bg-info\/50 {
  background-color: rgb(var(--color-info)/0.5);
}

.bg-info\/60 {
  background-color: rgb(var(--color-info)/0.6);
}

.bg-orange-800\/40 {
  background-color: rgba(154, 52, 18, 0.4);
}

.bg-orange-800\/80 {
  background-color: rgba(154, 52, 18, 0.8);
}

.bg-pending {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-pending)/var(--tw-bg-opacity));
}

.bg-pending\/10 {
  background-color: rgb(var(--color-pending)/0.1);
}

.bg-pending\/50 {
  background-color: rgb(var(--color-pending)/0.5);
}

.bg-pending\/80 {
  background-color: rgb(var(--color-pending)/0.8);
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.bg-primary\/10 {
  background-color: rgb(var(--color-primary)/0.1);
}

.bg-primary\/20 {
  background-color: rgb(var(--color-primary)/0.2);
}

.bg-primary\/30 {
  background-color: rgb(var(--color-primary)/0.3);
}

.bg-primary\/40 {
  background-color: rgb(var(--color-primary)/0.4);
}

.bg-primary\/5 {
  background-color: rgb(var(--color-primary)/0.05);
}

.bg-primary\/50 {
  background-color: rgb(var(--color-primary)/0.5);
}

.bg-primary\/60 {
  background-color: rgb(var(--color-primary)/0.6);
}

.bg-primary\/70 {
  background-color: rgb(var(--color-primary)/0.7);
}

.bg-primary\/80 {
  background-color: rgb(var(--color-primary)/0.8);
}

.bg-primary\/90 {
  background-color: rgb(var(--color-primary)/0.9);
}

.bg-primary\/\[0\.01\] {
  background-color: rgb(var(--color-primary)/0.01);
}

.bg-primary\/\[0\.03\] {
  background-color: rgb(var(--color-primary)/0.03);
}

.bg-primary\/\[0\.15\] {
  background-color: rgb(var(--color-primary)/0.15);
}

.bg-red-800\/40 {
  background-color: rgba(153, 27, 27, 0.4);
}

.bg-red-800\/80 {
  background-color: rgba(153, 27, 27, 0.8);
}

.bg-secondary\/70 {
  background-color: rgb(var(--color-secondary)/0.7);
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.bg-slate-100\/60 {
  background-color: rgba(241, 245, 249, 0.6);
}

.bg-slate-100\/70 {
  background-color: rgba(241, 245, 249, 0.7);
}

.bg-slate-100\/80 {
  background-color: rgba(241, 245, 249, 0.8);
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240/var(--tw-bg-opacity));
}

.bg-slate-200\/60 {
  background-color: rgba(226, 232, 240, 0.6);
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225/var(--tw-bg-opacity));
}

.bg-slate-300\/80 {
  background-color: rgba(203, 213, 225, 0.8);
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184/var(--tw-bg-opacity));
}

.bg-slate-400\/30 {
  background-color: rgba(148, 163, 184, 0.3);
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.bg-slate-50\/50 {
  background-color: rgba(248, 250, 252, 0.5);
}

.bg-slate-50\/60 {
  background-color: rgba(248, 250, 252, 0.6);
}

.bg-slate-50\/70 {
  background-color: rgba(248, 250, 252, 0.7);
}

.bg-slate-50\/80 {
  background-color: rgba(248, 250, 252, 0.8);
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139/var(--tw-bg-opacity));
}

.bg-slate-500\/60 {
  background-color: rgba(100, 116, 139, 0.6);
}

.bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-success)/var(--tw-bg-opacity));
}

.bg-success\/10 {
  background-color: rgb(var(--color-success)/0.1);
}

.bg-success\/50 {
  background-color: rgb(var(--color-success)/0.5);
}

.bg-success\/60 {
  background-color: rgb(var(--color-success)/0.6);
}

.bg-success\/70 {
  background-color: rgb(var(--color-success)/0.7);
}

.bg-success\/80 {
  background-color: rgb(var(--color-success)/0.8);
}

.bg-success\/\[0\.95\] {
  background-color: rgb(var(--color-success)/0.95);
}

.bg-teal-800\/40 {
  background-color: rgba(17, 94, 89, 0.4);
}

.bg-teal-800\/80 {
  background-color: rgba(17, 94, 89, 0.8);
}

.bg-theme-1 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-1)/var(--tw-bg-opacity));
}

.bg-theme-1\/10 {
  background-color: rgb(var(--color-theme-1)/0.1);
}

.bg-theme-1\/30 {
  background-color: rgb(var(--color-theme-1)/0.3);
}

.bg-theme-1\/5 {
  background-color: rgb(var(--color-theme-1)/0.05);
}

.bg-theme-1\/70 {
  background-color: rgb(var(--color-theme-1)/0.7);
}

.bg-theme-1\/80 {
  background-color: rgb(var(--color-theme-1)/0.8);
}

.bg-theme-1\/\[0\.75\] {
  background-color: rgb(var(--color-theme-1)/0.75);
}

.bg-theme-2 {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-theme-2)/var(--tw-bg-opacity));
}

.bg-theme-2\/30 {
  background-color: rgb(var(--color-theme-2)/0.3);
}

.bg-transparent {
  background-color: transparent;
}

.bg-warning {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-warning)/var(--tw-bg-opacity));
}

.bg-warning\/10 {
  background-color: rgb(var(--color-warning)/0.1);
}

.bg-warning\/5 {
  background-color: rgb(var(--color-warning)/0.05);
}

.bg-warning\/60 {
  background-color: rgb(var(--color-warning)/0.6);
}

.bg-warning\/\[0\.07\] {
  background-color: rgb(var(--color-warning)/0.07);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.bg-white\/10 {
  background-color: rgba(255, 255, 255, 0.1);
}

.bg-white\/20 {
  background-color: rgba(255, 255, 255, 0.2);
}

.bg-white\/5 {
  background-color: rgba(255, 255, 255, 0.05);
}

.bg-white\/70 {
  background-color: rgba(255, 255, 255, 0.7);
}

.bg-white\/80 {
  background-color: rgba(255, 255, 255, 0.8);
}

.bg-white\/\[0\.08\] {
  background-color: rgba(255, 255, 255, 0.08);
}

.bg-white\/\[0\.12\] {
  background-color: rgba(255, 255, 255, 0.12);
}

.bg-white\/\[0\.95\] {
  background-color: rgba(255, 255, 255, 0.95);
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138/var(--tw-bg-opacity));
}

.bg-yellow-800\/40 {
  background-color: rgba(133, 77, 14, 0.4);
}

.bg-yellow-800\/80 {
  background-color: rgba(133, 77, 14, 0.8);
}

.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}

.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}

.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}

.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.bg-file-icon-directory {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_3' data-name='Group 3' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.bg-file-icon-empty-directory {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_2' data-name='Group 2' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='786' height='721' rx='40' transform='translate(567 158)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.bg-file-icon-file {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='628.027' height='786.012' viewBox='0 0 628.027 786.012'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_5' data-name='Group 5' transform='translate(-646 -92.988)'%3E%3Cpath id='Union_2' data-name='Union 2' d='M40,786A40,40,0,0,1,0,746V40A40,40,0,0,1,40,0H501V103h29v24h98V746a40,40,0,0,1-40,40Z' transform='translate(646 93)' fill='url(%23linear-gradient)'/%3E%3Cpath id='Intersection_2' data-name='Intersection 2' d='M.409,162.042l.058-109.9c31.605,29.739,125.37,125.377,125.37,125.377l-109.976.049A20.025,20.025,0,0,1,.409,162.042Z' transform='translate(1147 42)' fill='%23bec8d9' stroke='%23bec8d9' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-slate-100 {
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-200\/70 {
  --tw-gradient-from: rgb(226 232 240 / 0.7) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-slate-50 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/20 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.2) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/40 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.4) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/50 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.5) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/60 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.6) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/70 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.7) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/90 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.9) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-1\/95 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.95) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-2\/90 {
  --tw-gradient-from: rgb(var(--color-theme-2) / 0.9) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-2) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-theme-2\/\[0\.85\] {
  --tw-gradient-from: rgb(var(--color-theme-2) / 0.85) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-2) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-500 {
  --tw-gradient-from: #eab308 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-theme-1 {
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops:
    var(--tw-gradient-from),
    rgb(var(--color-theme-1) / 1) var(--tw-gradient-via-position),
    var(--tw-gradient-to);
}

.via-theme-2\/50 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops:
    var(--tw-gradient-from),
    rgb(var(--color-theme-2) / 0.5) var(--tw-gradient-via-position),
    var(--tw-gradient-to);
}

.to-black\/50 {
  --tw-gradient-to: rgb(0 0 0 / 0.5) var(--tw-gradient-to-position);
}

.to-orange-500 {
  --tw-gradient-to: #f97316 var(--tw-gradient-to-position);
}

.to-slate-50 {
  --tw-gradient-to: #f8fafc var(--tw-gradient-to-position);
}

.to-theme-1\/90 {
  --tw-gradient-to: rgb(var(--color-theme-1) / 0.9) var(--tw-gradient-to-position);
}

.to-theme-1\/\[0\.03\] {
  --tw-gradient-to: rgb(var(--color-theme-1) / 0.03) var(--tw-gradient-to-position);
}

.to-theme-1\/\[0\.05\] {
  --tw-gradient-to: rgb(var(--color-theme-1) / 0.05) var(--tw-gradient-to-position);
}

.to-theme-1\/\[0\.85\] {
  --tw-gradient-to: rgb(var(--color-theme-1) / 0.85) var(--tw-gradient-to-position);
}

.to-theme-2 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 1) var(--tw-gradient-to-position);
}

.to-theme-2\/20 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.2) var(--tw-gradient-to-position);
}

.to-theme-2\/40 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.4) var(--tw-gradient-to-position);
}

.to-theme-2\/60 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.6) var(--tw-gradient-to-position);
}

.to-theme-2\/70 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.7) var(--tw-gradient-to-position);
}

.to-theme-2\/80 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.8) var(--tw-gradient-to-position);
}

.to-theme-2\/90 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.9) var(--tw-gradient-to-position);
}

.to-theme-2\/95 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.95) var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-\[length\:15px_auto\] {
  background-size: 15px auto;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-blue-500\/30 {
  fill: rgba(59, 130, 246, 0.3);
}

.fill-danger\/10 {
  fill: rgb(var(--color-danger)/0.1);
}

.fill-info\/10 {
  fill: rgb(var(--color-info)/0.1);
}

.fill-pending\/10 {
  fill: rgb(var(--color-pending)/0.1);
}

.fill-pending\/30 {
  fill: rgb(var(--color-pending)/0.3);
}

.fill-primary\/10 {
  fill: rgb(var(--color-primary)/0.1);
}

.fill-primary\/5 {
  fill: rgb(var(--color-primary)/0.05);
}

.fill-primary\/\[0\.03\] {
  fill: rgb(var(--color-primary)/0.03);
}

.fill-slate-100 {
  fill: #f1f5f9;
}

.fill-slate-300\/70 {
  fill: rgba(203, 213, 225, 0.7);
}

.fill-slate-400\/70 {
  fill: rgba(148, 163, 184, 0.7);
}

.fill-slate-500\/10 {
  fill: rgba(100, 116, 139, 0.1);
}

.fill-slate-500\/70 {
  fill: rgba(100, 116, 139, 0.7);
}

.fill-success\/10 {
  fill: rgb(var(--color-success)/0.1);
}

.fill-theme-1\/10 {
  fill: rgb(var(--color-theme-1)/0.1);
}

.fill-theme-1\/5 {
  fill: rgb(var(--color-theme-1)/0.05);
}

.fill-warning\/10 {
  fill: rgb(var(--color-warning)/0.1);
}

.fill-white\/10 {
  fill: rgba(255, 255, 255, 0.1);
}

.fill-white\/5 {
  fill: rgba(255, 255, 255, 0.05);
}

.fill-white\/60 {
  fill: rgba(255, 255, 255, 0.6);
}

.fill-white\/70 {
  fill: rgba(255, 255, 255, 0.7);
}

.fill-white\/80 {
  fill: rgba(255, 255, 255, 0.8);
}

.fill-white\/\[0\.03\] {
  fill: rgba(255, 255, 255, 0.03);
}

.stroke-slate-400\/50 {
  stroke: rgba(148, 163, 184, 0.5);
}

.stroke-slate-400\/70 {
  stroke: rgba(148, 163, 184, 0.7);
}

.stroke-slate-500\/70 {
  stroke: rgba(100, 116, 139, 0.7);
}

.stroke-white\/60 {
  stroke: rgba(255, 255, 255, 0.6);
}

.stroke-white\/70 {
  stroke: rgba(255, 255, 255, 0.7);
}

.stroke-\[0\.3\] {
  stroke-width: 0.3;
}

.stroke-\[0\.5\] {
  stroke-width: 0.5;
}

.stroke-\[0\.7\] {
  stroke-width: 0.7;
}

.stroke-\[0\.8\] {
  stroke-width: 0.8;
}

.stroke-\[0\.9\] {
  stroke-width: 0.9;
}

.stroke-\[1\.2\] {
  stroke-width: 1.2;
}

.stroke-\[1\.3\] {
  stroke-width: 1.3;
}

.stroke-\[1\.4\] {
  stroke-width: 1.4;
}

.stroke-\[1\.5\] {
  stroke-width: 1.5;
}

.stroke-\[1\.7\] {
  stroke-width: 1.7;
}

.stroke-\[1\] {
  stroke-width: 1;
}

.stroke-\[2\] {
  stroke-width: 2;
}

.\!p-0 {
  padding: 0px !important;
}

.p-0 {
  padding: 0px;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-px {
  padding: 1px;
}

.\!px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-\[2px\] {
  padding-top: 2px;
  padding-bottom: 2px;
}

.py-\[9\.2px\] {
  padding-top: 9.2px;
  padding-bottom: 9.2px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.\!pl-2 {
  padding-left: 0.5rem !important;
}

.\!pl-4 {
  padding-left: 1rem !important;
}

.\!pr-2 {
  padding-right: 0.5rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-3\.5 {
  padding-bottom: 0.875rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0px;
}

.pl-0\.5 {
  padding-left: 0.125rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pl-2\.5 {
  padding-left: 0.625rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pl-3\.5 {
  padding-left: 0.875rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pl-\[7px\] {
  padding-left: 7px;
}

.pl-\[84px\] {
  padding-left: 84px;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pr-1\.5 {
  padding-right: 0.375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pt-3\.5 {
  padding-top: 0.875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[100\%\] {
  padding-top: 100%;
}

.pt-\[137px\] {
  padding-top: 137px;
}

.pt-\[31px\] {
  padding-top: 31px;
}

.pt-\[39px\] {
  padding-top: 39px;
}

.pt-\[54px\] {
  padding-top: 54px;
}

.pt-\[56px\] {
  padding-top: 56px;
}

.pt-\[65px\] {
  padding-top: 65px;
}

.pt-\[66px\] {
  padding-top: 66px;
}

.pt-\[84\%\] {
  padding-top: 84%;
}

.pt-\[94px\] {
  padding-top: 94px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[0\.6rem\] {
  font-size: 0.6rem;
}

.text-\[0\.8rem\] {
  font-size: 0.8rem;
}

.text-\[0\.94rem\] {
  font-size: 0.94rem;
}

.text-\[1\.3rem\] {
  font-size: 1.3rem;
}

.text-\[13px\] {
  font-size: 13px;
}

.text-\[2\.1rem\] {
  font-size: 2.1rem;
}

.text-\[2\.6rem\] {
  font-size: 2.6rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.leading-\[0\] {
  line-height: 0;
}

.leading-\[1\.2\] {
  line-height: 1.2;
}

.leading-\[1\.4\] {
  line-height: 1.4;
}

.leading-\[1\.75\] {
  line-height: 1.75;
}

.leading-\[1\.7\] {
  line-height: 1.7;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-widest {
  letter-spacing: 0.1em;
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14/var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246/var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175/var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117/var(--tw-text-opacity));
}

.text-danger {
  --tw-text-opacity: 1;
  color: rgb(var(--color-danger)/var(--tw-text-opacity));
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(var(--color-dark)/var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99/var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52/var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: rgb(var(--color-info)/var(--tw-text-opacity));
}

.text-orange-800 {
  --tw-text-opacity: 1;
  color: rgb(154 52 18/var(--tw-text-opacity));
}

.text-pending {
  --tw-text-opacity: 1;
  color: rgb(var(--color-pending)/var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.text-primary\/50 {
  color: rgb(var(--color-primary)/0.5);
}

.text-primary\/70 {
  color: rgb(var(--color-primary)/0.7);
}

.text-primary\/80 {
  color: rgb(var(--color-primary)/0.8);
}

.text-primary\/90 {
  color: rgb(var(--color-primary)/0.9);
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27/var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184/var(--tw-text-opacity));
}

.text-slate-400\/60 {
  color: rgba(148, 163, 184, 0.6);
}

.text-slate-400\/80 {
  color: rgba(148, 163, 184, 0.8);
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139/var(--tw-text-opacity));
}

.text-slate-500\/60 {
  color: rgba(100, 116, 139, 0.6);
}

.text-slate-500\/70 {
  color: rgba(100, 116, 139, 0.7);
}

.text-slate-500\/80 {
  color: rgba(100, 116, 139, 0.8);
}

.text-slate-500\/90 {
  color: rgba(100, 116, 139, 0.9);
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105/var(--tw-text-opacity));
}

.text-slate-600\/80 {
  color: rgba(71, 85, 105, 0.8);
}

.text-slate-600\/90 {
  color: rgba(71, 85, 105, 0.9);
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85/var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59/var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42/var(--tw-text-opacity));
}

.text-success {
  --tw-text-opacity: 1;
  color: rgb(var(--color-success)/var(--tw-text-opacity));
}

.text-teal-800 {
  --tw-text-opacity: 1;
  color: rgb(17 94 89/var(--tw-text-opacity));
}

.text-theme-1 {
  --tw-text-opacity: 1;
  color: rgb(var(--color-theme-1)/var(--tw-text-opacity));
}

.text-theme-1\/20 {
  color: rgb(var(--color-theme-1)/0.2);
}

.text-theme-1\/70 {
  color: rgb(var(--color-theme-1)/0.7);
}

.text-warning {
  --tw-text-opacity: 1;
  color: rgb(var(--color-warning)/var(--tw-text-opacity));
}

.text-warning\/80 {
  color: rgb(var(--color-warning)/0.8);
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.text-white\/10 {
  color: rgba(255, 255, 255, 0.1);
}

.text-white\/20 {
  color: rgba(255, 255, 255, 0.2);
}

.text-white\/50 {
  color: rgba(255, 255, 255, 0.5);
}

.text-white\/60 {
  color: rgba(255, 255, 255, 0.6);
}

.text-white\/70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white\/80 {
  color: rgba(255, 255, 255, 0.8);
}

.text-white\/90 {
  color: rgba(255, 255, 255, 0.9);
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14/var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-primary\/30 {
  text-decoration-color: rgb(var(--color-primary)/0.3);
}

.decoration-primary\/50 {
  text-decoration-color: rgb(var(--color-primary)/0.5);
}

.decoration-slate-300 {
  text-decoration-color: #cbd5e1;
}

.decoration-slate-500\/30 {
  text-decoration-color: rgba(100, 116, 139, 0.3);
}

.decoration-warning\/50 {
  text-decoration-color: rgb(var(--color-warning)/0.5);
}

.decoration-dotted {
  text-decoration-style: dotted;
}

.underline-offset-\[3px\] {
  text-underline-offset: 3px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-90 {
  opacity: 0.9;
}

.opacity-\[0\.95\] {
  opacity: 0.95;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-\[0px_0px_0px_2px_\#fff\,_1px_1px_5px_rgba\(0\,0\,0\,0\.32\)\] {
  --tw-shadow: 0px 0px 0px 2px #fff, 1px 1px 5px rgba(0,0,0,0.32);
  --tw-shadow-colored: 0px 0px 0px 2px var(--tw-shadow-color), 1px 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-\[0px_3px_10px_\#00000017\] {
  --tw-shadow: 0px 3px 10px #00000017;
  --tw-shadow-colored: 0px 3px 10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-\[5px_3px_5px_\#00000005\] {
  --tw-shadow: 5px 3px 5px #00000005;
  --tw-shadow-colored: 5px 3px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.outline {
  outline-style: solid;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-150 {
  --tw-saturate: saturate(1.5);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-\[\.6\] {
  --tw-saturate: saturate(.6);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.saturate-\[0\.7\] {
  --tw-saturate: saturate(0.7);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[margin\,padding\] {
  transition-property: margin, padding;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[margin\,width\] {
  transition-property: margin, width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[margin-right\] {
  transition-property: margin-right;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[margin-top\,transform\] {
  transition-property: margin-top, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[margin\] {
  transition-property: margin;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[opacity\,transform\] {
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[padding\,background-color\,border-color\] {
  transition-property: padding, background-color, border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[padding\] {
  transition-property: padding;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[visibility\,opacity\] {
  transition-property: visibility, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[width\,margin\] {
  transition-property: width, margin;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[width\] {
  transition-property: width;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-100 {
  transition-duration: 100ms;
}

.duration-150 {
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 200ms;
}

.duration-300 {
  transition-duration: 300ms;
}

.duration-\[0\.4s\,0\.3s\] {
  transition-duration: 0.4s, 0.3s;
}

.duration-\[0\.6s\] {
  transition-duration: 0.6s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.ease-linear {
  transition-timing-function: linear;
}

.\[-webkit-mask-image\:-webkit-linear-gradient\(top\,rgba\(0\,0\,0\,0\)\,black_30px\)\] {
  -webkit-mask-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0), black 30px);
}

.before\:box::before {
  content: var(--tw-content);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.0431372549);
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  position: relative;
}

.dark .before\:box::before {
  content: var(--tw-content);
  background-color: rgb(var(--color-darkmode-600)/1);
  border-color: rgb(var(--color-darkmode-500)/1);
}

.group.top-bar--active .group-\[\.top-bar--active\]\:box {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.0431372549);
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.6rem;
  position: relative;
}

.dark .group.top-bar--active .group-\[\.top-bar--active\]\:box {
  background-color: rgb(var(--color-darkmode-600)/1);
  border-color: rgb(var(--color-darkmode-500)/1);
}

.file\:mr-4::file-selector-button {
  margin-right: 1rem;
}

.file\:rounded-l-md::file-selector-button {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.file\:border-0::file-selector-button {
  border-width: 0px;
}

.file\:border-r-\[1px\]::file-selector-button {
  border-right-width: 1px;
}

.file\:border-slate-100\/10::file-selector-button {
  border-color: rgba(241, 245, 249, 0.1);
}

.file\:bg-slate-100::file-selector-button {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.file\:px-4::file-selector-button {
  padding-left: 1rem;
  padding-right: 1rem;
}

.file\:py-2::file-selector-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.file\:text-sm::file-selector-button {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.file\:font-semibold::file-selector-button {
  font-weight: 600;
}

.file\:text-slate-500\/70::file-selector-button {
  color: rgba(100, 116, 139, 0.7);
}

.placeholder\:text-slate-400\/90::-moz-placeholder {
  color: rgba(148, 163, 184, 0.9);
}

.placeholder\:text-slate-400\/90::placeholder {
  color: rgba(148, 163, 184, 0.9);
}

.placeholder\:text-white\/50::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.placeholder\:text-white\/50::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.before\:fixed::before {
  content: var(--tw-content);
  position: fixed;
}

.before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.before\:inset-0::before {
  content: var(--tw-content);
  inset: 0px;
}

.before\:inset-x-0::before {
  content: var(--tw-content);
  left: 0px;
  right: 0px;
}

.before\:inset-x-4::before {
  content: var(--tw-content);
  left: 1rem;
  right: 1rem;
}

.before\:inset-y-0::before {
  content: var(--tw-content);
  top: 0px;
  bottom: 0px;
}

.before\:left-0::before {
  content: var(--tw-content);
  left: 0px;
}

.before\:right-0::before {
  content: var(--tw-content);
  right: 0px;
}

.before\:top-0::before {
  content: var(--tw-content);
  top: 0px;
}

.before\:z-10::before {
  content: var(--tw-content);
  z-index: 10;
}

.before\:z-\[-1\]::before {
  content: var(--tw-content);
  z-index: -1;
}

.before\:z-\[60\]::before {
  content: var(--tw-content);
  z-index: 60;
}

.before\:mx-5::before {
  content: var(--tw-content);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.before\:mx-auto::before {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}

.before\:my-6::before {
  content: var(--tw-content);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.before\:my-auto::before {
  content: var(--tw-content);
  margin-top: auto;
  margin-bottom: auto;
}

.before\:-mb-3::before {
  content: var(--tw-content);
  margin-bottom: -0.75rem;
}

.before\:-mb-3\.5::before {
  content: var(--tw-content);
  margin-bottom: -0.875rem;
}

.before\:-ml-5::before {
  content: var(--tw-content);
  margin-left: -1.25rem;
}

.before\:-ml-\[1\.125rem\]::before {
  content: var(--tw-content);
  margin-left: -1.125rem;
}

.before\:-ml-\[25\%\]::before {
  content: var(--tw-content);
  margin-left: -25%;
}

.before\:-ml-\[35\%\]::before {
  content: var(--tw-content);
  margin-left: -35%;
}

.before\:-ml-px::before {
  content: var(--tw-content);
  margin-left: -1px;
}

.before\:-mr-28::before {
  content: var(--tw-content);
  margin-right: -7rem;
}

.before\:-mr-4::before {
  content: var(--tw-content);
  margin-right: -1rem;
}

.before\:-mr-\[62\%\]::before {
  content: var(--tw-content);
  margin-right: -62%;
}

.before\:-mt-1::before {
  content: var(--tw-content);
  margin-top: -0.25rem;
}

.before\:-mt-2::before {
  content: var(--tw-content);
  margin-top: -0.5rem;
}

.before\:-mt-2\.5::before {
  content: var(--tw-content);
  margin-top: -0.625rem;
}

.before\:-mt-5::before {
  content: var(--tw-content);
  margin-top: -1.25rem;
}

.before\:-mt-\[15px\]::before {
  content: var(--tw-content);
  margin-top: -15px;
}

.before\:-mt-\[4\.5rem\]::before {
  content: var(--tw-content);
  margin-top: -4.5rem;
}

.before\:-mt-\[50rem\]::before {
  content: var(--tw-content);
  margin-top: -50rem;
}

.before\:ml-1::before {
  content: var(--tw-content);
  margin-left: 0.25rem;
}

.before\:ml-5::before {
  content: var(--tw-content);
  margin-left: 1.25rem;
}

.before\:ml-\[14px\]::before {
  content: var(--tw-content);
  margin-left: 14px;
}

.before\:mr-4::before {
  content: var(--tw-content);
  margin-right: 1rem;
}

.before\:mr-5::before {
  content: var(--tw-content);
  margin-right: 1.25rem;
}

.before\:mt-3::before {
  content: var(--tw-content);
  margin-top: 0.75rem;
}

.before\:block::before {
  content: var(--tw-content);
  display: block;
}

.before\:hidden::before {
  content: var(--tw-content);
  display: none;
}

.before\:h-14::before {
  content: var(--tw-content);
  height: 3.5rem;
}

.before\:h-2::before {
  content: var(--tw-content);
  height: 0.5rem;
}

.before\:h-2\.5::before {
  content: var(--tw-content);
  height: 0.625rem;
}

.before\:h-4::before {
  content: var(--tw-content);
  height: 1rem;
}

.before\:h-4\/6::before {
  content: var(--tw-content);
  height: 66.666667%;
}

.before\:h-5::before {
  content: var(--tw-content);
  height: 1.25rem;
}

.before\:h-5\/6::before {
  content: var(--tw-content);
  height: 83.333333%;
}

.before\:h-64::before {
  content: var(--tw-content);
  height: 16rem;
}

.before\:h-\[130\%\]::before {
  content: var(--tw-content);
  height: 130%;
}

.before\:h-\[14px\]::before {
  content: var(--tw-content);
  height: 14px;
}

.before\:h-\[20px\]::before {
  content: var(--tw-content);
  height: 20px;
}

.before\:h-\[2px\]::before {
  content: var(--tw-content);
  height: 2px;
}

.before\:h-\[360\%\]::before {
  content: var(--tw-content);
  height: 360%;
}

.before\:h-\[370px\]::before {
  content: var(--tw-content);
  height: 370px;
}

.before\:h-\[60\%\]::before {
  content: var(--tw-content);
  height: 60%;
}

.before\:h-full::before {
  content: var(--tw-content);
  height: 100%;
}

.before\:h-px::before {
  content: var(--tw-content);
  height: 1px;
}

.before\:h-screen::before {
  content: var(--tw-content);
  height: 100vh;
}

.before\:w-0::before {
  content: var(--tw-content);
  width: 0px;
}

.before\:w-0\.5::before {
  content: var(--tw-content);
  width: 0.125rem;
}

.before\:w-1::before {
  content: var(--tw-content);
  width: 0.25rem;
}

.before\:w-1\.5::before {
  content: var(--tw-content);
  width: 0.375rem;
}

.before\:w-14::before {
  content: var(--tw-content);
  width: 3.5rem;
}

.before\:w-4::before {
  content: var(--tw-content);
  width: 1rem;
}

.before\:w-5::before {
  content: var(--tw-content);
  width: 1.25rem;
}

.before\:w-64::before {
  content: var(--tw-content);
  width: 16rem;
}

.before\:w-\[14px\]::before {
  content: var(--tw-content);
  width: 14px;
}

.before\:w-\[150\%\]::before {
  content: var(--tw-content);
  width: 150%;
}

.before\:w-\[20px\]::before {
  content: var(--tw-content);
  width: 20px;
}

.before\:w-\[800\%\]::before {
  content: var(--tw-content);
  width: 800%;
}

.before\:w-full::before {
  content: var(--tw-content);
  width: 100%;
}

.before\:w-px::before {
  content: var(--tw-content);
  width: 1px;
}

.before\:w-screen::before {
  content: var(--tw-content);
  width: 100vw;
}

.before\:-rotate-\[38deg\]::before {
  content: var(--tw-content);
  --tw-rotate: -38deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-45::before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rotate-\[-90deg\]::before {
  content: var(--tw-content);
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:transform::before {
  content: var(--tw-content);
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:rounded-2xl::before {
  content: var(--tw-content);
  border-radius: 1rem;
}

.before\:rounded-\[0_0_50\%\]::before {
  content: var(--tw-content);
  border-radius: 0 0 50%;
}

.before\:rounded-\[0_1\.2rem_1\.2rem_0\/0_1\.7rem_1\.7rem_0\]::before {
  content: var(--tw-content);
  border-radius: 0 1.2rem 1.2rem 0/0 1.7rem 1.7rem 0;
}

.before\:rounded-full::before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:rounded-lg::before {
  content: var(--tw-content);
  border-radius: 0.5rem;
}

.before\:rounded-xl::before {
  content: var(--tw-content);
  border-radius: 0.75rem;
}

.before\:rounded-bl::before {
  content: var(--tw-content);
  border-bottom-left-radius: 0.25rem;
}

.before\:border::before {
  content: var(--tw-content);
  border-width: 1px;
}

.before\:border-0::before {
  content: var(--tw-content);
  border-width: 0px;
}

.before\:border-r::before {
  content: var(--tw-content);
  border-right-width: 1px;
}

.before\:border-dashed::before {
  content: var(--tw-content);
  border-style: dashed;
}

.before\:border-dotted::before {
  content: var(--tw-content);
  border-style: dotted;
}

.before\:border-slate-200::before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240/var(--tw-border-opacity));
}

.before\:border-slate-200\/80::before {
  content: var(--tw-content);
  border-color: rgba(226, 232, 240, 0.8);
}

.before\:border-slate-300\/50::before {
  content: var(--tw-content);
  border-color: rgba(203, 213, 225, 0.5);
}

.before\:border-slate-300\/70::before {
  content: var(--tw-content);
  border-color: rgba(203, 213, 225, 0.7);
}

.before\:border-white\/\[0\.15\]::before {
  content: var(--tw-content);
  border-color: rgba(255, 255, 255, 0.15);
}

.before\:bg-primary\/20::before {
  content: var(--tw-content);
  background-color: rgb(var(--color-primary)/0.2);
}

.before\:bg-slate-100::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.before\:bg-slate-200::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240/var(--tw-bg-opacity));
}

.before\:bg-slate-200\/60::before {
  content: var(--tw-content);
  background-color: rgba(226, 232, 240, 0.6);
}

.before\:bg-slate-200\/70::before {
  content: var(--tw-content);
  background-color: rgba(226, 232, 240, 0.7);
}

.before\:bg-slate-300\/60::before {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.6);
}

.before\:bg-slate-50::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.before\:bg-white::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.before\:bg-white\/40::before {
  content: var(--tw-content);
  background-color: rgba(255, 255, 255, 0.4);
}

.before\:bg-white\/50::before {
  content: var(--tw-content);
  background-color: rgba(255, 255, 255, 0.5);
}

.before\:bg-opacity-70::before {
  content: var(--tw-content);
  --tw-bg-opacity: 0.7;
}

.before\:bg-chevron-black::before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2300000095' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.before\:bg-chevron-white::before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff95' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.before\:bg-gradient-to-b::before {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-r::before {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.before\:bg-gradient-to-t::before {
  content: var(--tw-content);
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.before\:bg-texture-black::before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2346.899' height='1200.894' viewBox='0 0 2346.899 1200.894'%3E%3Cg id='Group_369' data-name='Group 369' transform='translate(-33.74 508.575)'%3E%3Cg id='Group_366' data-name='Group 366' transform='translate(33.74 -458.541)'%3E%3Crect id='Rectangle_492' data-name='Rectangle 492' width='745.289' height='650.113' transform='matrix(0.978, 0.208, -0.208, 0.978, 296.729, 261.648)' fill='rgba(30,41,59,0.01)'/%3E%3Crect id='Rectangle_491' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(30,41,59,0.01)'/%3E%3C/g%3E%3Cg id='Group_367' data-name='Group 367' transform='translate(1647.456 1026.688) rotate(-128)'%3E%3Crect id='Rectangle_492-2' data-name='Rectangle 492' width='745.289' height='650.113' transform='matrix(0.978, 0.208, -0.208, 0.978, 296.729, 261.648)' fill='rgba(30,41,59,0.01)'/%3E%3Crect id='Rectangle_491-2' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(30,41,59,0.01)'/%3E%3C/g%3E%3Cg id='Group_368' data-name='Group 368' transform='matrix(-0.656, -0.755, 0.755, -0.656, 1017.824, 1042.94)'%3E%3Crect id='Rectangle_492-3' data-name='Rectangle 492' width='745.289' height='650.113' transform='matrix(0.978, 0.208, -0.208, 0.978, 296.729, 261.648)' fill='rgba(30,41,59,0.01)'/%3E%3Crect id='Rectangle_491-3' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(30,41,59,0.01)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.before\:bg-texture-white::before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2346.899' height='1200.894' viewBox='0 0 2346.899 1200.894'%3E%3Cg id='Group_369' data-name='Group 369' transform='translate(-33.74 508.575)'%3E%3Cg id='Group_366' data-name='Group 366' transform='translate(33.74 -458.541)'%3E%3Crect id='Rectangle_492' data-name='Rectangle 492' width='745.289' height='650.113' transform='translate(296.729 261.648) rotate(12.007)' fill='rgba(255,255,255,0.014)'/%3E%3Crect id='Rectangle_491' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(255,255,255,0.014)'/%3E%3C/g%3E%3Cg id='Group_367' data-name='Group 367' transform='translate(1647.456 1026.688) rotate(-128)'%3E%3Crect id='Rectangle_492-2' data-name='Rectangle 492' width='745.289' height='650.113' transform='translate(296.729 261.648) rotate(12.007)' fill='rgba(255,255,255,0.014)'/%3E%3Crect id='Rectangle_491-2' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(255,255,255,0.014)'/%3E%3C/g%3E%3Cg id='Group_368' data-name='Group 368' transform='matrix(-0.656, -0.755, 0.755, -0.656, 1017.824, 1042.94)'%3E%3Crect id='Rectangle_492-3' data-name='Rectangle 492' width='745.289' height='650.113' transform='translate(296.729 261.648) rotate(12.007)' fill='rgba(255,255,255,0.014)'/%3E%3Crect id='Rectangle_491-3' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(255,255,255,0.014)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.before\:from-black\/\[0\.08\]::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(0 0 0 / 0.08) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-black\/\[0\.15\]::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(0 0 0 / 0.15) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-slate-100::before {
  content: var(--tw-content);
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-slate-900\/90::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(15 23 42 / 0.9) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-theme-1::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(var(--color-theme-1) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-theme-1\/30::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.3) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-theme-1\/60::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.6) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-theme-1\/80::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.8) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-transparent::before {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:from-white\/10::before {
  content: var(--tw-content);
  --tw-gradient-from: rgb(255 255 255 / 0.1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:via-slate-100\/80::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops:
    var(--tw-gradient-from),
    rgb(241 245 249 / 0.8) var(--tw-gradient-via-position),
    var(--tw-gradient-to);
}

.before\:via-theme-2\/30::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(var(--color-theme-2) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops:
    var(--tw-gradient-from),
    rgb(var(--color-theme-2) / 0.3) var(--tw-gradient-via-position),
    var(--tw-gradient-to);
}

.before\:to-black\/20::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(0 0 0 / 0.2) var(--tw-gradient-to-position);
}

.before\:to-slate-100::before {
  content: var(--tw-content);
  --tw-gradient-to: #f1f5f9 var(--tw-gradient-to-position);
}

.before\:to-slate-50::before {
  content: var(--tw-content);
  --tw-gradient-to: #f8fafc var(--tw-gradient-to-position);
}

.before\:to-slate-50\/10::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(248 250 252 / 0.1) var(--tw-gradient-to-position);
}

.before\:to-theme-2::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(var(--color-theme-2) / 1) var(--tw-gradient-to-position);
}

.before\:to-theme-2\/5::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.05) var(--tw-gradient-to-position);
}

.before\:to-theme-2\/70::before {
  content: var(--tw-content);
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.7) var(--tw-gradient-to-position);
}

.before\:to-transparent::before {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.before\:bg-\[length\:100\%\]::before {
  content: var(--tw-content);
  background-size: 100%;
}

.before\:bg-contain::before {
  content: var(--tw-content);
  background-size: contain;
}

.before\:bg-cover::before {
  content: var(--tw-content);
  background-size: cover;
}

.before\:bg-fixed::before {
  content: var(--tw-content);
  background-attachment: fixed;
}

.before\:bg-\[center_-20rem\]::before {
  content: var(--tw-content);
  background-position: center -20rem;
}

.before\:bg-\[center_1rem\]::before {
  content: var(--tw-content);
  background-position: center 1rem;
}

.before\:bg-no-repeat::before {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.before\:px-4::before {
  content: var(--tw-content);
  padding-left: 1rem;
  padding-right: 1rem;
}

.before\:py-2::before {
  content: var(--tw-content);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.before\:pt-\[100\%\]::before {
  content: var(--tw-content);
  padding-top: 100%;
}

.before\:font-medium::before {
  content: var(--tw-content);
  font-weight: 500;
}

.before\:opacity-0::before {
  content: var(--tw-content);
  opacity: 0;
}

.before\:opacity-90::before {
  content: var(--tw-content);
  opacity: 0.9;
}

.before\:shadow-\[1px_1px_3px_rgba\(0\,0\,0\,0\.25\)\]::before {
  content: var(--tw-content);
  --tw-shadow: 1px 1px 3px rgba(0,0,0,0.25);
  --tw-shadow-colored: 1px 1px 3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.before\:shadow-sm::before {
  content: var(--tw-content);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.before\:backdrop-blur::before {
  content: var(--tw-content);
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.before\:transition-\[margin-left\]::before {
  content: var(--tw-content);
  transition-property: margin-left;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.before\:transition-\[opacity\,height\]::before {
  content: var(--tw-content);
  transition-property: opacity, height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.before\:transition-colors::before {
  content: var(--tw-content);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.before\:transition-opacity::before {
  content: var(--tw-content);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.before\:duration-200::before {
  content: var(--tw-content);
  transition-duration: 200ms;
}

.before\:duration-300::before {
  content: var(--tw-content);
  transition-duration: 300ms;
}

.before\:ease-in-out::before {
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.before\:content-\[\'\'\]::before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:content-\[\'HTML\'\]::before {
  --tw-content: "HTML";
  content: var(--tw-content);
}

.after\:fixed::after {
  content: var(--tw-content);
  position: fixed;
}

.after\:absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:inset-0::after {
  content: var(--tw-content);
  inset: 0px;
}

.after\:inset-x-0::after {
  content: var(--tw-content);
  left: 0px;
  right: 0px;
}

.after\:inset-x-4::after {
  content: var(--tw-content);
  left: 1rem;
  right: 1rem;
}

.after\:inset-y-0::after {
  content: var(--tw-content);
  top: 0px;
  bottom: 0px;
}

.after\:left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.after\:left-\[-1\.25rem\]::after {
  content: var(--tw-content);
  left: -1.25rem;
}

.after\:right-0::after {
  content: var(--tw-content);
  right: 0px;
}

.after\:top-0::after {
  content: var(--tw-content);
  top: 0px;
}

.after\:z-10::after {
  content: var(--tw-content);
  z-index: 10;
}

.after\:z-\[-1\]::after {
  content: var(--tw-content);
  z-index: -1;
}

.after\:z-\[61\]::after {
  content: var(--tw-content);
  z-index: 61;
}

.after\:m-auto::after {
  content: var(--tw-content);
  margin: auto;
}

.after\:mx-auto::after {
  content: var(--tw-content);
  margin-left: auto;
  margin-right: auto;
}

.after\:my-auto::after {
  content: var(--tw-content);
  margin-top: auto;
  margin-bottom: auto;
}

.after\:-ml-5::after {
  content: var(--tw-content);
  margin-left: -1.25rem;
}

.after\:-mr-2::after {
  content: var(--tw-content);
  margin-right: -0.5rem;
}

.after\:-mr-20::after {
  content: var(--tw-content);
  margin-right: -5rem;
}

.after\:-mr-4::after {
  content: var(--tw-content);
  margin-right: -1rem;
}

.after\:-mt-\[15px\]::after {
  content: var(--tw-content);
  margin-top: -15px;
}

.after\:-mt-\[50rem\]::after {
  content: var(--tw-content);
  margin-top: -50rem;
}

.after\:ml-5::after {
  content: var(--tw-content);
  margin-left: 1.25rem;
}

.after\:ml-\[11px\]::after {
  content: var(--tw-content);
  margin-left: 11px;
}

.after\:mt-20::after {
  content: var(--tw-content);
  margin-top: 5rem;
}

.after\:mt-3::after {
  content: var(--tw-content);
  margin-top: 0.75rem;
}

.after\:mt-5::after {
  content: var(--tw-content);
  margin-top: 1.25rem;
}

.after\:hidden::after {
  content: var(--tw-content);
  display: none;
}

.after\:h-1::after {
  content: var(--tw-content);
  height: 0.25rem;
}

.after\:h-1\.5::after {
  content: var(--tw-content);
  height: 0.375rem;
}

.after\:h-16::after {
  content: var(--tw-content);
  height: 4rem;
}

.after\:h-4::after {
  content: var(--tw-content);
  height: 1rem;
}

.after\:h-8::after {
  content: var(--tw-content);
  height: 2rem;
}

.after\:h-80::after {
  content: var(--tw-content);
  height: 20rem;
}

.after\:h-\[14px\]::after {
  content: var(--tw-content);
  height: 14px;
}

.after\:h-\[2px\]::after {
  content: var(--tw-content);
  height: 2px;
}

.after\:h-\[370px\]::after {
  content: var(--tw-content);
  height: 370px;
}

.after\:h-full::after {
  content: var(--tw-content);
  height: 100%;
}

.after\:h-screen::after {
  content: var(--tw-content);
  height: 100vh;
}

.after\:w-1::after {
  content: var(--tw-content);
  width: 0.25rem;
}

.after\:w-1\.5::after {
  content: var(--tw-content);
  width: 0.375rem;
}

.after\:w-16::after {
  content: var(--tw-content);
  width: 4rem;
}

.after\:w-2::after {
  content: var(--tw-content);
  width: 0.5rem;
}

.after\:w-4::after {
  content: var(--tw-content);
  width: 1rem;
}

.after\:w-8::after {
  content: var(--tw-content);
  width: 2rem;
}

.after\:w-80::after {
  content: var(--tw-content);
  width: 20rem;
}

.after\:w-\[800\%\]::after {
  content: var(--tw-content);
  width: 800%;
}

.after\:w-full::after {
  content: var(--tw-content);
  width: 100%;
}

.after\:w-screen::after {
  content: var(--tw-content);
  width: 100vw;
}

@keyframes pulse {
  50% {
    content: var(--tw-content);
    opacity: 0.5;
  }
}
.after\:animate-pulse::after {
  content: var(--tw-content);
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.after\:rounded-\[0_1\.2rem_1\.2rem_0\/0_1\.7rem_1\.7rem_0\]::after {
  content: var(--tw-content);
  border-radius: 0 1.2rem 1.2rem 0/0 1.7rem 1.7rem 0;
}

.after\:rounded-full::after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:rounded-lg::after {
  content: var(--tw-content);
  border-radius: 0.5rem;
}

.after\:border::after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-4::after {
  content: var(--tw-content);
  border-width: 4px;
}

.after\:border-dashed::after {
  content: var(--tw-content);
  border-style: dashed;
}

.after\:border-primary\/10::after {
  content: var(--tw-content);
  border-color: rgb(var(--color-primary)/0.1);
}

.after\:border-white\/60::after {
  content: var(--tw-content);
  border-color: rgba(255, 255, 255, 0.6);
}

.after\:border-white\/\[0\.15\]::after {
  content: var(--tw-content);
  border-color: rgba(255, 255, 255, 0.15);
}

.after\:bg-black\/80::after {
  content: var(--tw-content);
  background-color: rgba(0, 0, 0, 0.8);
}

.after\:bg-primary::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.after\:bg-primary\/5::after {
  content: var(--tw-content);
  background-color: rgb(var(--color-primary)/0.05);
}

.after\:bg-slate-300\/60::after {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.6);
}

.after\:bg-slate-500::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139/var(--tw-bg-opacity));
}

.after\:bg-white::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.after\:bg-\[radial-gradient\(rgb\(0_0_0_\/_10\%\)_1px\,_transparent_0\)\]::after {
  content: var(--tw-content);
  background-image: radial-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 0);
}

.after\:bg-gradient-to-b::after {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.after\:bg-gradient-to-r::after {
  content: var(--tw-content);
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.after\:bg-loading-puff::after {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' viewBox='0 0 44 44' %3E%3Cg stroke='white' fill='none' fill-rule='evenodd' stroke-width='4' %3E%3Ccircle cx='22' cy='22' r='1' %3E%3Canimate values='1; 20' attributeName='r' begin='0s' dur='1.8s' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate values='1; 0' attributeName='stroke-opacity' begin='0s' dur='1.8s' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3Ccircle cx='22' cy='22' r='1' %3E%3Canimate values='1; 20' attributeName='r' begin='-0.9s' dur='1.8s' calcMode='spline' keyTimes='0; 1' keySplines='0.165, 0.84, 0.44, 1' repeatCount='indefinite' /%3E%3Canimate values='1; 0' attributeName='stroke-opacity' begin='-0.9s' dur='1.8s' calcMode='spline' keyTimes='0; 1' keySplines='0.3, 0.61, 0.355, 1' repeatCount='indefinite' /%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
}

.after\:bg-texture-white::after {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='2346.899' height='1200.894' viewBox='0 0 2346.899 1200.894'%3E%3Cg id='Group_369' data-name='Group 369' transform='translate(-33.74 508.575)'%3E%3Cg id='Group_366' data-name='Group 366' transform='translate(33.74 -458.541)'%3E%3Crect id='Rectangle_492' data-name='Rectangle 492' width='745.289' height='650.113' transform='translate(296.729 261.648) rotate(12.007)' fill='rgba(255,255,255,0.014)'/%3E%3Crect id='Rectangle_491' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(255,255,255,0.014)'/%3E%3C/g%3E%3Cg id='Group_367' data-name='Group 367' transform='translate(1647.456 1026.688) rotate(-128)'%3E%3Crect id='Rectangle_492-2' data-name='Rectangle 492' width='745.289' height='650.113' transform='translate(296.729 261.648) rotate(12.007)' fill='rgba(255,255,255,0.014)'/%3E%3Crect id='Rectangle_491-2' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(255,255,255,0.014)'/%3E%3C/g%3E%3Cg id='Group_368' data-name='Group 368' transform='matrix(-0.656, -0.755, 0.755, -0.656, 1017.824, 1042.94)'%3E%3Crect id='Rectangle_492-3' data-name='Rectangle 492' width='745.289' height='650.113' transform='translate(296.729 261.648) rotate(12.007)' fill='rgba(255,255,255,0.014)'/%3E%3Crect id='Rectangle_491-3' data-name='Rectangle 491' width='1335.276' height='650.113' transform='translate(0 543.106) rotate(-24)' fill='rgba(255,255,255,0.014)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.after\:from-slate-100::after {
  content: var(--tw-content);
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:from-theme-1::after {
  content: var(--tw-content);
  --tw-gradient-from: rgb(var(--color-theme-1) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:from-white::after {
  content: var(--tw-content);
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.after\:to-slate-100\/80::after {
  content: var(--tw-content);
  --tw-gradient-to: rgb(241 245 249 / 0.8) var(--tw-gradient-to-position);
}

.after\:to-slate-50::after {
  content: var(--tw-content);
  --tw-gradient-to: #f8fafc var(--tw-gradient-to-position);
}

.after\:to-theme-2::after {
  content: var(--tw-content);
  --tw-gradient-to: rgb(var(--color-theme-2) / 1) var(--tw-gradient-to-position);
}

.after\:bg-\[length\:100vw_65px\]::after {
  content: var(--tw-content);
  background-size: 100vw 65px;
}

.after\:bg-\[length\:25px_25px\]::after {
  content: var(--tw-content);
  background-size: 25px 25px;
}

.after\:bg-contain::after {
  content: var(--tw-content);
  background-size: contain;
}

.after\:bg-cover::after {
  content: var(--tw-content);
  background-size: cover;
}

.after\:bg-fixed::after {
  content: var(--tw-content);
  background-attachment: fixed;
}

.after\:bg-\[1\.25rem_top\]::after {
  content: var(--tw-content);
  background-position: 1.25rem top;
}

.after\:bg-\[center_-13rem\]::after {
  content: var(--tw-content);
  background-position: center -13rem;
}

.after\:bg-\[center_-20rem\]::after {
  content: var(--tw-content);
  background-position: center -20rem;
}

.after\:bg-\[center_-25rem\]::after {
  content: var(--tw-content);
  background-position: center -25rem;
}

.after\:bg-\[center_-5rem\]::after {
  content: var(--tw-content);
  background-position: center -5rem;
}

.after\:bg-center::after {
  content: var(--tw-content);
  background-position: center;
}

.after\:bg-no-repeat::after {
  content: var(--tw-content);
  background-repeat: no-repeat;
}

.after\:opacity-0::after {
  content: var(--tw-content);
  opacity: 0;
}

.after\:opacity-50::after {
  content: var(--tw-content);
  opacity: 0.5;
}

.after\:shadow-sm::after {
  content: var(--tw-content);
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.after\:backdrop-blur::after {
  content: var(--tw-content);
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.after\:transition-\[opacity\,height\]::after {
  content: var(--tw-content);
  transition-property: opacity, height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:transition-opacity::after {
  content: var(--tw-content);
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.after\:duration-300::after {
  content: var(--tw-content);
  transition-duration: 300ms;
}

.after\:ease-in-out::after {
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.after\:content-\[\'\'\]::after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:-mt-3:first-child {
  margin-top: -0.75rem;
}

.first\:-mt-4:first-child {
  margin-top: -1rem;
}

.first\:ml-0:first-child {
  margin-left: 0px;
}

.first\:mt-0:first-child {
  margin-top: 0px;
}

.first\:rounded-b-none:first-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.first\:rounded-l:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.first\:rounded-l-\[0\.6rem\]:first-child {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}

.first\:rounded-l-sm:first-child {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}

.first\:rounded-tl-\[0\.6rem\]:first-child {
  border-top-left-radius: 0.6rem;
}

.first\:rounded-tl-lg:first-child {
  border-top-left-radius: 0.5rem;
}

.first\:border-l:first-child {
  border-left-width: 1px;
}

.first\:pt-0:first-child {
  padding-top: 0px;
}

.first\:before\:absolute:first-child::before {
  content: var(--tw-content);
  position: absolute;
}

.first\:before\:hidden:first-child::before {
  content: var(--tw-content);
  display: none;
}

.first\:before\:h-1\/2:first-child::before {
  content: var(--tw-content);
  height: 50%;
}

.first\:before\:w-5:first-child::before {
  content: var(--tw-content);
  width: 1.25rem;
}

.first\:before\:bg-white:first-child::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.first\:before\:content-\[\'\'\]:first-child::before {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:after\:ml-5:first-child::after {
  content: var(--tw-content);
  margin-left: 1.25rem;
}

.first\:after\:h-\[2px\]:first-child::after {
  content: var(--tw-content);
  height: 2px;
}

.first\:after\:w-full:first-child::after {
  content: var(--tw-content);
  width: 100%;
}

.first\:after\:bg-slate-300\/60:first-child::after {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.6);
}

.first\:after\:content-\[\'\'\]:first-child::after {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:-mb-3:last-child {
  margin-bottom: -0.75rem;
}

.last\:-mb-4:last-child {
  margin-bottom: -1rem;
}

.last\:-mt-px:last-child {
  margin-top: -1px;
}

.last\:mb-0:last-child {
  margin-bottom: 0px;
}

.last\:mb-2:last-child {
  margin-bottom: 0.5rem;
}

.last\:rounded-r:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.last\:rounded-r-\[0\.6rem\]:last-child {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.last\:rounded-r-sm:last-child {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}

.last\:rounded-t-none:last-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.last\:rounded-tr-\[0\.6rem\]:last-child {
  border-top-right-radius: 0.6rem;
}

.last\:rounded-tr-lg:last-child {
  border-top-right-radius: 0.5rem;
}

.last\:border-0:last-child {
  border-width: 0px;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0px;
}

.last\:border-r:last-child {
  border-right-width: 1px;
}

.last\:border-r-0:last-child {
  border-right-width: 0px;
}

.last\:pb-0:last-child {
  padding-bottom: 0px;
}

.last\:before\:mr-5:last-child::before {
  content: var(--tw-content);
  margin-right: 1.25rem;
}

.last\:before\:h-\[2px\]:last-child::before {
  content: var(--tw-content);
  height: 2px;
}

.last\:before\:w-full:last-child::before {
  content: var(--tw-content);
  width: 100%;
}

.last\:before\:bg-slate-300\/60:last-child::before {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.6);
}

.last\:before\:content-\[\'\'\]:last-child::before {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:after\:absolute:last-child::after {
  content: var(--tw-content);
  position: absolute;
}

.last\:after\:bottom-0:last-child::after {
  content: var(--tw-content);
  bottom: 0px;
}

.last\:after\:hidden:last-child::after {
  content: var(--tw-content);
  display: none;
}

.last\:after\:h-1\/2:last-child::after {
  content: var(--tw-content);
  height: 50%;
}

.last\:after\:w-5:last-child::after {
  content: var(--tw-content);
  width: 1.25rem;
}

.last\:after\:bg-white:last-child::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.last\:after\:content-\[\'\'\]:last-child::after {
  --tw-content: "";
  content: var(--tw-content);
}

.checked\:border-primary:checked {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.checked\:bg-primary:checked {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.checked\:bg-none:checked {
  background-image: none;
}

.before\:checked\:ml-\[14px\]:checked::before {
  content: var(--tw-content);
  margin-left: 14px;
}

.before\:checked\:bg-white:checked::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.hover\:relative:hover {
  position: relative;
}

.hover\:rotate-180:hover {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:border-slate-100:hover {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249/var(--tw-border-opacity));
}

.hover\:bg-primary\/20:hover {
  background-color: rgb(var(--color-primary)/0.2);
}

.hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.hover\:bg-slate-100\/80:hover {
  background-color: rgba(241, 245, 249, 0.8);
}

.hover\:bg-slate-200\/50:hover {
  background-color: rgba(226, 232, 240, 0.5);
}

.hover\:bg-slate-200\/60:hover {
  background-color: rgba(226, 232, 240, 0.6);
}

.hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.hover\:bg-slate-50\/80:hover {
  background-color: rgba(248, 250, 252, 0.8);
}

.hover\:bg-slate-600\/5:hover {
  background-color: rgba(71, 85, 105, 0.05);
}

.hover\:bg-white\/10:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.hover\:bg-white\/20:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.hover\:bg-white\/5:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.hover\:bg-white\/60:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.hover\:bg-white\/\[0\.15\]:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.hover\:saturate-100:hover {
  --tw-saturate: saturate(1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:duration-100:hover {
  transition-duration: 100ms;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.focus\:border-opacity-40:focus {
  --tw-border-opacity: 0.4;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(var(--color-primary) / var(--tw-ring-opacity));
}

.focus\:ring-opacity-20:focus {
  --tw-ring-opacity: 0.2;
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus-visible\:outline-none:focus-visible {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:bg-slate-100:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.disabled\:opacity-70:disabled {
  opacity: 0.7;
}

.group.right .group-\[\.right\]\:left-0 {
  left: 0px;
}

.group.input-group .group-\[\.input-group\]\:z-10 {
  z-index: 10;
}

.group.form-inline .group-\[\.form-inline\]\:mb-2 {
  margin-bottom: 0.5rem;
}

.group.right .group-\[\.right\]\:-ml-8 {
  margin-left: -2rem;
}

.group.right .group-\[\.right\]\:ml-auto {
  margin-left: auto;
}

.group.right .group-\[\.right\]\:mr-0 {
  margin-right: 0px;
}

.group.show .group-\[\.show\]\:mr-0 {
  margin-right: 0px;
}

.group.show .group-\[\.show\]\:mt-16 {
  margin-top: 4rem;
}

.group.side-menu--mobile-menu-open .group-\[\.side-menu--mobile-menu-open\]\:ml-0 {
  margin-left: 0px;
}

.group.global-search--show-result .group-\[\.global-search--show-result\]\:block {
  display: block;
}

.group.global-search--show-result .group-\[\.global-search--show-result\]\:hidden {
  display: none;
}

.group.form-inline .group-\[\.form-inline\]\:flex-1 {
  flex: 1 1 0%;
}

.group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.modal-static .group-\[\.modal-static\]\:scale-\[1\.05\] {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.show .group-\[\.show\]\:scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.right .group-\[\.right\]\:flex-row-reverse {
  flex-direction: row-reverse;
}

.group.right .group-\[\.right\]\:justify-end {
  justify-content: flex-end;
}

.group.input-group .group-\[\.input-group\]\:rounded-none {
  border-radius: 0px;
}

.group.right .group-\[\.right\]\:rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.group.right .group-\[\.right\]\:rounded-br-none {
  border-bottom-right-radius: 0px;
}

.group.background--hidden .group-\[\.background--hidden\]\:border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.group.mode--light .group-\[\.mode--light\]\:\!border-transparent {
  border-color: transparent !important;
}

.group.mode--light .group-\[\.mode--light\]\:\!border-white\/\[0\.25\] {
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.group.mode--light .group-\[\.mode--light\]\:border-slate-300\/20 {
  border-color: rgba(203, 213, 225, 0.2);
}

.group.pending .group-\[\.pending\]\:border-pending\/50 {
  border-color: rgb(var(--color-pending)/0.5);
}

.group.primary .group-\[\.primary\]\:border-primary\/50 {
  border-color: rgb(var(--color-primary)/0.5);
}

.group.success .group-\[\.success\]\:border-success\/50 {
  border-color: rgb(var(--color-success)/0.5);
}

.group.top-bar--active .group-\[\.top-bar--active\]\:border-transparent {
  border-color: transparent;
}

.group.active .group-\[\.active\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.group.background--hidden .group-\[\.background--hidden\]\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.group.info .group-\[\.info\]\:bg-info\/80 {
  background-color: rgb(var(--color-info)/0.8);
}

.group.mode--light .group-\[\.mode--light\]\:\!bg-transparent {
  background-color: transparent !important;
}

.group.mode--light .group-\[\.mode--light\]\:\!bg-white\/\[0\.12\] {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.group.mode--light .group-\[\.mode--light\]\:bg-slate-300\/10 {
  background-color: rgba(203, 213, 225, 0.1);
}

.group.mode--light .group-\[\.mode--light\]\:bg-transparent {
  background-color: transparent;
}

.group.pending .group-\[\.pending\]\:bg-pending\/50 {
  background-color: rgb(var(--color-pending)/0.5);
}

.group.primary .group-\[\.primary\]\:bg-primary\/50 {
  background-color: rgb(var(--color-primary)/0.5);
}

.group.primary .group-\[\.primary\]\:bg-primary\/80 {
  background-color: rgb(var(--color-primary)/0.8);
}

.group.success .group-\[\.success\]\:bg-success\/50 {
  background-color: rgb(var(--color-success)/0.5);
}

.group.success .group-\[\.success\]\:bg-success\/80 {
  background-color: rgb(var(--color-success)/0.8);
}

.group.top-bar--active .group-\[\.top-bar--active\]\:bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.group.top-bar--active .group-\[\.top-bar--active\]\:bg-transparent {
  background-color: transparent;
}

.group.warning .group-\[\.warning\]\:bg-warning\/80 {
  background-color: rgb(var(--color-warning)/0.8);
}

.group.mode--light .group-\[\.mode--light\]\:bg-chevron-white {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff95' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.group.top-bar--active .group-\[\.top-bar--active\]\:bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.group.top-bar--active .group-\[\.top-bar--active\]\:from-theme-1 {
  --tw-gradient-from: rgb(var(--color-theme-1) / 1) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.group.top-bar--active .group-\[\.top-bar--active\]\:to-theme-2 {
  --tw-gradient-to: rgb(var(--color-theme-2) / 1) var(--tw-gradient-to-position);
}

.group.primary .group-\[\.primary\]\:fill-primary\/10 {
  fill: rgb(var(--color-primary)/0.1);
}

.group.success .group-\[\.success\]\:fill-success\/10 {
  fill: rgb(var(--color-success)/0.1);
}

.group.top-bar--active .group-\[\.top-bar--active\]\:px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.group.right .group-\[\.right\]\:text-right {
  text-align: right;
}

.group.active .group-\[\.active\]\:text-current {
  color: currentColor;
}

.group.active .group-\[\.active\]\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.group.background--hidden .group-\[\.background--hidden\]\:text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105/var(--tw-text-opacity));
}

.group.background--hidden .group-\[\.background--hidden\]\:text-slate-600\/70 {
  color: rgba(71, 85, 105, 0.7);
}

.group.mode--light .group-\[\.mode--light\]\:\!text-slate-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240/var(--tw-text-opacity)) !important;
}

.group.mode--light .group-\[\.mode--light\]\:\!text-slate-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(203 213 225/var(--tw-text-opacity)) !important;
}

.group.mode--light .group-\[\.mode--light\]\:text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity));
}

.group.mode--light .group-\[\.mode--light\]\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.group.mode--light .group-\[\.mode--light\]\:text-white\/80 {
  color: rgba(255, 255, 255, 0.8);
}

.group.mode--light .group-\[\.mode--light\]\:text-white\/90 {
  color: rgba(255, 255, 255, 0.9);
}

.group.primary .group-\[\.primary\]\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.group.success .group-\[\.success\]\:text-success {
  --tw-text-opacity: 1;
  color: rgb(var(--color-success)/var(--tw-text-opacity));
}

.group.top-bar--active .group-\[\.top-bar--active\]\:shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.group.mode--light .group-\[\.mode--light\]\:before\:bg-slate-300\/20::before {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.2);
}

.group.background--hidden .group-\[\.background--hidden\]\:before\:opacity-100::before {
  content: var(--tw-content);
  opacity: 1;
}

.group.side-menu--mobile-menu-open .group-\[\.side-menu--mobile-menu-open\]\:after\:block::after {
  content: var(--tw-content);
  display: block;
}

.group.mode--light .group-\[\.mode--light\]\:after\:bg-slate-300\/20::after {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.2);
}

.group.background--hidden .group-\[\.background--hidden\]\:after\:opacity-100::after {
  content: var(--tw-content);
  opacity: 1;
}

.group.input-group .group-\[\.input-group\]\:first\:rounded-l:first-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.group.mode--light .group-\[\.mode--light\]\:first\:after\:bg-slate-300\/20:first-child::after {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.2);
}

.group.input-group .group-\[\.input-group\]\:last\:rounded-r:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.group.mode--light .group-\[\.mode--light\]\:last\:before\:bg-slate-300\/20:last-child::before {
  content: var(--tw-content);
  background-color: rgba(203, 213, 225, 0.2);
}

.group.top-bar--active .group-\[\.top-bar--active\]\:hover\:bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.group.top-bar--active .group-\[\.top-bar--active\]\:hover\:bg-slate-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.peer:checked ~ .peer-checked\:fill-primary\/50 {
  fill: rgb(var(--color-primary)/0.5);
}

.peer:checked ~ .peer-checked\:text-primary {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.peer:checked ~ .peer-checked\:opacity-0 {
  opacity: 0;
}

.peer:checked ~ .peer-checked\:opacity-100 {
  opacity: 1;
}

.peer:hover ~ .peer-hover\:rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:hover ~ .peer-hover\:bg-amber-800\/10 {
  background-color: rgba(146, 64, 14, 0.1);
}

.peer:hover ~ .peer-hover\:bg-blue-800\/10 {
  background-color: rgba(30, 64, 175, 0.1);
}

.peer:hover ~ .peer-hover\:bg-cyan-800\/10 {
  background-color: rgba(21, 94, 117, 0.1);
}

.peer:hover ~ .peer-hover\:bg-green-800\/10 {
  background-color: rgba(22, 101, 52, 0.1);
}

.peer:hover ~ .peer-hover\:bg-orange-800\/10 {
  background-color: rgba(154, 52, 18, 0.1);
}

.peer:hover ~ .peer-hover\:bg-primary\/10 {
  background-color: rgb(var(--color-primary)/0.1);
}

.peer:hover ~ .peer-hover\:bg-teal-800\/10 {
  background-color: rgba(17, 94, 89, 0.1);
}

.peer:hover ~ .peer-hover\:bg-theme-1\/10 {
  background-color: rgb(var(--color-theme-1)/0.1);
}

.peer:hover ~ .peer-hover\:bg-yellow-800\/10 {
  background-color: rgba(133, 77, 14, 0.1);
}

.peer:hover ~ .peer-hover\:fill-primary\/10 {
  fill: rgb(var(--color-primary)/0.1);
}

.peer:hover:checked ~ .peer-hover\:peer-checked\:fill-primary\/50 {
  fill: rgb(var(--color-primary)/0.5);
}

.dark\:border:is(.dark *) {
  border-width: 1px;
}

.dark\:border-0:is(.dark *) {
  border-width: 0px;
}

.dark\:border-\[\#0077b5\]:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(0 119 181/var(--tw-border-opacity));
}

.dark\:border-\[\#3b5998\]:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(59 89 152/var(--tw-border-opacity));
}

.dark\:border-\[\#4ab3f4\]:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(74 179 244/var(--tw-border-opacity));
}

.dark\:border-\[\#517fa4\]:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(81 127 164/var(--tw-border-opacity));
}

.dark\:border-danger:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-danger)/var(--tw-border-opacity));
}

.dark\:border-darkmode-100\/30:is(.dark *) {
  border-color: rgb(var(--color-darkmode-100)/0.3);
}

.dark\:border-darkmode-100\/40:is(.dark *) {
  border-color: rgb(var(--color-darkmode-100)/0.4);
}

.dark\:border-darkmode-300:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-300)/var(--tw-border-opacity));
}

.dark\:border-darkmode-400:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-400)/var(--tw-border-opacity));
}

.dark\:border-darkmode-600:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-600)/var(--tw-border-opacity));
}

.dark\:border-darkmode-800:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-800)/var(--tw-border-opacity));
}

.dark\:border-darkmode-800\/60:is(.dark *) {
  border-color: rgb(var(--color-darkmode-800)/0.6);
}

.dark\:border-darkmode-900\/20:is(.dark *) {
  border-color: rgb(var(--color-darkmode-900)/0.2);
}

.dark\:border-pending:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-pending)/var(--tw-border-opacity));
}

.dark\:border-primary:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.dark\:border-success:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-success)/var(--tw-border-opacity));
}

.dark\:border-transparent:is(.dark *) {
  border-color: transparent;
}

.dark\:border-warning:is(.dark *) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-warning)/var(--tw-border-opacity));
}

.dark\:border-opacity-100:is(.dark *) {
  --tw-border-opacity: 1;
}

.dark\:border-opacity-20:is(.dark *) {
  --tw-border-opacity: 0.2;
}

.dark\:bg-black\/20:is(.dark *) {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark\:bg-black\/30:is(.dark *) {
  background-color: rgba(0, 0, 0, 0.3);
}

.dark\:bg-darkmode-100\/20:is(.dark *) {
  background-color: rgb(var(--color-darkmode-100)/0.2);
}

.dark\:bg-darkmode-300:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-300)/var(--tw-bg-opacity));
}

.dark\:bg-darkmode-400:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-400)/var(--tw-bg-opacity));
}

.dark\:bg-darkmode-400\/70:is(.dark *) {
  background-color: rgb(var(--color-darkmode-400)/0.7);
}

.dark\:bg-darkmode-600:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-600)/var(--tw-bg-opacity));
}

.dark\:bg-darkmode-700:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-700)/var(--tw-bg-opacity));
}

.dark\:bg-darkmode-800:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-800)/var(--tw-bg-opacity));
}

.dark\:bg-darkmode-800\/30:is(.dark *) {
  background-color: rgb(var(--color-darkmode-800)/0.3);
}

.dark\:bg-darkmode-900\/20:is(.dark *) {
  background-color: rgb(var(--color-darkmode-900)/0.2);
}

.dark\:bg-slate-200:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240/var(--tw-bg-opacity));
}

.dark\:bg-transparent:is(.dark *) {
  background-color: transparent;
}

.dark\:bg-opacity-20:is(.dark *) {
  --tw-bg-opacity: 0.2;
}

.dark\:text-slate-200:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity));
}

.dark\:text-slate-300:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225/var(--tw-text-opacity));
}

.dark\:text-slate-400:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(148 163 184/var(--tw-text-opacity));
}

.dark\:text-slate-500:is(.dark *) {
  --tw-text-opacity: 1;
  color: rgb(100 116 139/var(--tw-text-opacity));
}

.dark\:shadow-\[0px_0px_0px_2px_\#3f4865\,_1px_1px_5px_rgba\(0\,0\,0\,0\.32\)\]:is(.dark *) {
  --tw-shadow: 0px 0px 0px 2px #3f4865, 1px 1px 5px rgba(0,0,0,0.32);
  --tw-shadow-colored: 0px 0px 0px 2px var(--tw-shadow-color), 1px 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.dark\:placeholder\:text-slate-500\/80:is(.dark *)::-moz-placeholder {
  color: rgba(100, 116, 139, 0.8);
}

.dark\:placeholder\:text-slate-500\/80:is(.dark *)::placeholder {
  color: rgba(100, 116, 139, 0.8);
}

.before\:dark\:border-darkmode-500\/60:is(.dark *)::before {
  content: var(--tw-content);
  border-color: rgb(var(--color-darkmode-500)/0.6);
}

.before\:dark\:bg-darkmode-300:is(.dark *)::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-300)/var(--tw-bg-opacity));
}

.before\:dark\:bg-darkmode-400:is(.dark *)::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-400)/var(--tw-bg-opacity));
}

.before\:dark\:bg-darkmode-600:is(.dark *)::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-600)/var(--tw-bg-opacity));
}

.before\:dark\:bg-darkmode-600\/70:is(.dark *)::before {
  content: var(--tw-content);
  background-color: rgb(var(--color-darkmode-600)/0.7);
}

.dark\:before\:bg-chevron-black:is(.dark *)::before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%2300000095' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.dark\:before\:bg-chevron-white:is(.dark *)::before {
  content: var(--tw-content);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ffffff95' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.after\:dark\:border-darkmode-300:is(.dark *)::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-300)/var(--tw-border-opacity));
}

.after\:dark\:border-darkmode-500\/60:is(.dark *)::after {
  content: var(--tw-content);
  border-color: rgb(var(--color-darkmode-500)/0.6);
}

.after\:dark\:bg-darkmode-200:is(.dark *)::after {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-200)/var(--tw-bg-opacity));
}

.after\:dark\:bg-darkmode-600\/70:is(.dark *)::after {
  content: var(--tw-content);
  background-color: rgb(var(--color-darkmode-600)/0.7);
}

.dark\:hover\:bg-darkmode-400:hover:is(.dark *) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-400)/var(--tw-bg-opacity));
}

.dark\:focus\:ring-slate-700:focus:is(.dark *) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity));
}

.dark\:focus\:ring-opacity-50:focus:is(.dark *) {
  --tw-ring-opacity: 0.5;
}

.dark\:disabled\:border-transparent:disabled:is(.dark *) {
  border-color: transparent;
}

.dark\:disabled\:bg-darkmode-800\/50:disabled:is(.dark *) {
  background-color: rgb(var(--color-darkmode-800)/0.5);
}

.disabled\:dark\:bg-darkmode-800\/50:is(.dark *):disabled {
  background-color: rgb(var(--color-darkmode-800)/0.5);
}

@media (min-width: 640px) {
  .sm\:absolute {
    position: absolute;
  }
  .sm\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .sm\:col-span-10 {
    grid-column: span 10/span 10;
  }
  .sm\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .sm\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .sm\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .sm\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .sm\:col-start-2 {
    grid-column-start: 2;
  }
  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .sm\:-ml-12 {
    margin-left: -3rem;
  }
  .sm\:-ml-\[105px\] {
    margin-left: -105px;
  }
  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }
  .sm\:mb-0 {
    margin-bottom: 0px;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem;
  }
  .sm\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .sm\:ml-20 {
    margin-left: 5rem;
  }
  .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  .sm\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:ml-40 {
    margin-left: 10rem;
  }
  .sm\:ml-5 {
    margin-left: 1.25rem;
  }
  .sm\:ml-7 {
    margin-left: 1.75rem;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:mr-0 {
    margin-right: 0px;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem;
  }
  .sm\:mr-20 {
    margin-right: 5rem;
  }
  .sm\:mr-28 {
    margin-right: 7rem;
  }
  .sm\:mr-40 {
    margin-right: 10rem;
  }
  .sm\:mr-5 {
    margin-right: 1.25rem;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem;
  }
  .sm\:mt-40 {
    margin-top: 10rem;
  }
  .sm\:mt-8 {
    margin-top: 2rem;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:block {
    display: block;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:grid {
    display: grid;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:h-14 {
    height: 3.5rem;
  }
  .sm\:h-16 {
    height: 4rem;
  }
  .sm\:h-20 {
    height: 5rem;
  }
  .sm\:h-5 {
    height: 1.25rem;
  }
  .sm\:h-7 {
    height: 1.75rem;
  }
  .sm\:h-8 {
    height: 2rem;
  }
  .sm\:max-h-\[615px\] {
    max-height: 615px;
  }
  .sm\:w-1\/2 {
    width: 50%;
  }
  .sm\:w-14 {
    width: 3.5rem;
  }
  .sm\:w-20 {
    width: 5rem;
  }
  .sm\:w-24 {
    width: 6rem;
  }
  .sm\:w-28 {
    width: 7rem;
  }
  .sm\:w-32 {
    width: 8rem;
  }
  .sm\:w-36 {
    width: 9rem;
  }
  .sm\:w-44 {
    width: 11rem;
  }
  .sm\:w-48 {
    width: 12rem;
  }
  .sm\:w-5 {
    width: 1.25rem;
  }
  .sm\:w-52 {
    width: 13rem;
  }
  .sm\:w-56 {
    width: 14rem;
  }
  .sm\:w-64 {
    width: 16rem;
  }
  .sm\:w-8 {
    width: 2rem;
  }
  .sm\:w-\[102px\] {
    width: 102px;
  }
  .sm\:w-\[300px\] {
    width: 300px;
  }
  .sm\:w-\[460px\] {
    width: 460px;
  }
  .sm\:w-\[600px\] {
    width: 600px;
  }
  .sm\:w-\[80\%\] {
    width: 80%;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:min-w-\[40px\] {
    min-width: 40px;
  }
  .sm\:max-w-\[9rem\] {
    max-width: 9rem;
  }
  .sm\:max-w-none {
    max-width: none;
  }
  .sm\:flex-initial {
    flex: 0 1 auto;
  }
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:gap-3 {
    gap: 0.75rem;
  }
  .sm\:gap-3\.5 {
    gap: 0.875rem;
  }
  .sm\:gap-7 {
    gap: 1.75rem;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible;
  }
  .sm\:border-r {
    border-right-width: 1px;
  }
  .sm\:p-14 {
    padding: 3.5rem;
  }
  .sm\:p-5 {
    padding: 1.25rem;
  }
  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .sm\:pl-5 {
    padding-left: 1.25rem;
  }
  .sm\:text-left {
    text-align: left;
  }
  .sm\:text-right {
    text-align: right;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .group.form-inline .group-\[\.form-inline\]\:sm\:mb-0 {
    margin-bottom: 0px;
  }
  .group.form-inline .group-\[\.form-inline\]\:sm\:mr-5 {
    margin-right: 1.25rem;
  }
  .group.form-inline .group-\[\.form-inline\]\:sm\:text-right {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .md\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .md\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .md\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .md\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:-mb-4 {
    margin-bottom: -1rem;
  }
  .md\:-mt-2 {
    margin-top: -0.5rem;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:mr-2 {
    margin-right: 0.5rem;
  }
  .md\:mt-0 {
    margin-top: 0px;
  }
  .md\:h-10 {
    height: 2.5rem;
  }
  .md\:w-24 {
    width: 6rem;
  }
  .md\:w-36 {
    width: 9rem;
  }
  .md\:w-52 {
    width: 13rem;
  }
  .md\:w-56 {
    width: 14rem;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:max-w-\[10rem\] {
    max-width: 10rem;
  }
  .md\:max-w-\[12rem\] {
    max-width: 12rem;
  }
  .md\:max-w-\[17rem\] {
    max-width: 17rem;
  }
  .md\:max-w-\[8rem\] {
    max-width: 8rem;
  }
  .md\:max-w-\[9rem\] {
    max-width: 9rem;
  }
  .md\:max-w-none {
    max-width: none;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:border-r {
    border-right-width: 1px;
  }
  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .md\:text-left {
    text-align: left;
  }
  .md\:text-right {
    text-align: right;
  }
  .first\:md\:rounded-r-none:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .first\:md\:rounded-bl-md:first-child {
    border-bottom-left-radius: 0.375rem;
  }
  .last\:md\:-ml-px:last-child {
    margin-left: -1px;
  }
  .last\:md\:mt-0:last-child {
    margin-top: 0px;
  }
  .last\:md\:rounded-l-none:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .last\:md\:rounded-tr-md:last-child {
    border-top-right-radius: 0.375rem;
  }
  .group.mode--light .md\:group-\[\.mode--light\]\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
  }
}
@media (min-width: 1024px) {
  .lg\:relative {
    position: relative;
  }
  .lg\:order-last {
    order: 9999;
  }
  .lg\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .lg\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .lg\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .lg\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .lg\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .lg\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .lg\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:mt-16 {
    margin-top: 4rem;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem;
  }
  .lg\:block {
    display: block;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:h-10 {
    height: 2.5rem;
  }
  .lg\:h-screen {
    height: 100vh;
  }
  .lg\:w-56 {
    width: 14rem;
  }
  .lg\:w-\[23rem\] {
    width: 23rem;
  }
  .lg\:w-\[700px\] {
    width: 700px;
  }
  .lg\:w-\[900px\] {
    width: 900px;
  }
  .lg\:max-w-\[1550px\] {
    max-width: 1550px;
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:justify-center {
    justify-content: center;
  }
  .lg\:gap-8 {
    gap: 2rem;
  }
  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .lg\:overflow-visible {
    overflow: visible;
  }
  .lg\:bg-transparent {
    background-color: transparent;
  }
  .lg\:p-0 {
    padding: 0px;
  }
  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .lg\:pl-14 {
    padding-left: 3.5rem;
  }
  .lg\:pr-10 {
    padding-right: 2.5rem;
  }
  .lg\:pr-12 {
    padding-right: 3rem;
  }
  .lg\:text-left {
    text-align: left;
  }
  .lg\:text-center {
    text-align: center;
  }
  .lg\:text-right {
    text-align: right;
  }
  .before\:lg\:-ml-10::before {
    content: var(--tw-content);
    margin-left: -2.5rem;
  }
  .before\:lg\:block::before {
    content: var(--tw-content);
    display: block;
  }
  .before\:lg\:w-\[800\%\]::before {
    content: var(--tw-content);
    width: 800%;
  }
  @keyframes ping {
    75%, 100% {
      content: var(--tw-content);
      transform: scale(2);
      opacity: 0;
    }
  }
  .lg\:before\:animate-ping::before {
    content: var(--tw-content);
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .after\:lg\:block::after {
    content: var(--tw-content);
    display: block;
  }
  .after\:lg\:w-\[800\%\]::after {
    content: var(--tw-content);
    width: 800%;
  }
  .after\:lg\:bg-\[25rem_-25rem\]::after {
    content: var(--tw-content);
    background-position: 25rem -25rem;
  }
  .lg\:first\:justify-start:first-child {
    justify-content: flex-start;
  }
  .lg\:last\:justify-end:last-child {
    justify-content: flex-end;
  }
  .group.mode--light .lg\:group-\[\.mode--light\]\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
  }
}
@media (min-width: 1280px) {
  .xl\:relative {
    position: relative;
  }
  .xl\:left-3 {
    left: 0.75rem;
  }
  .xl\:left-3\.5 {
    left: 0.875rem;
  }
  .xl\:top-\[65px\] {
    top: 65px;
  }
  .xl\:z-0 {
    z-index: 0;
  }
  .xl\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .xl\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .xl\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .xl\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .xl\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .xl\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .xl\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .xl\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .xl\:col-start-9 {
    grid-column-start: 9;
  }
  .xl\:-ml-2 {
    margin-left: -0.5rem;
  }
  .xl\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .xl\:mb-0 {
    margin-bottom: 0px;
  }
  .xl\:ml-0 {
    margin-left: 0px;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .xl\:ml-20 {
    margin-left: 5rem;
  }
  .xl\:ml-28 {
    margin-left: 7rem;
  }
  .xl\:ml-5 {
    margin-left: 1.25rem;
  }
  .xl\:ml-60 {
    margin-left: 15rem;
  }
  .xl\:ml-\[275px\] {
    margin-left: 275px;
  }
  .xl\:ml-auto {
    margin-left: auto;
  }
  .xl\:mr-14 {
    margin-right: 3.5rem;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .xl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xl\:mr-3 {
    margin-right: 0.75rem;
  }
  .xl\:mt-0 {
    margin-top: 0px;
  }
  .xl\:mt-3 {
    margin-top: 0.75rem;
  }
  .xl\:mt-8 {
    margin-top: 2rem;
  }
  .xl\:block {
    display: block;
  }
  .xl\:flex {
    display: flex;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:w-1\/2 {
    width: 50%;
  }
  .xl\:w-20 {
    width: 5rem;
  }
  .xl\:w-40 {
    width: 10rem;
  }
  .xl\:w-60 {
    width: 15rem;
  }
  .xl\:w-64 {
    width: 16rem;
  }
  .xl\:w-\[275px\] {
    width: 275px;
  }
  .xl\:w-\[300px\] {
    width: 300px;
  }
  .xl\:w-full {
    width: 100%;
  }
  .xl\:min-w-0 {
    min-width: 0px;
  }
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:flex-col {
    flex-direction: column;
  }
  .xl\:items-start {
    align-items: flex-start;
  }
  .xl\:items-center {
    align-items: center;
  }
  .xl\:justify-end {
    justify-content: flex-end;
  }
  .xl\:gap-0 {
    gap: 0px;
  }
  .xl\:gap-14 {
    gap: 3.5rem;
  }
  .xl\:overflow-visible {
    overflow: visible;
  }
  .xl\:rounded-\[0_1\.2rem_1\.2rem_0\/0_1\.7rem_1\.7rem_0\] {
    border-radius: 0 1.2rem 1.2rem 0/0 1.7rem 1.7rem 0;
  }
  .xl\:rounded-xl {
    border-radius: 0.75rem;
  }
  .xl\:border-0 {
    border-width: 0px;
  }
  .xl\:bg-slate-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(241 245 249/var(--tw-bg-opacity));
  }
  .xl\:p-0 {
    padding: 0px;
  }
  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .xl\:pl-14 {
    padding-left: 3.5rem;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem;
  }
  .xl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xl\:pl-3 {
    padding-left: 0.75rem;
  }
  .xl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xl\:pl-5 {
    padding-left: 1.25rem;
  }
  .xl\:pl-\[275px\] {
    padding-left: 275px;
  }
  .xl\:pr-10 {
    padding-right: 2.5rem;
  }
  .xl\:pr-20 {
    padding-right: 5rem;
  }
  .xl\:pr-24 {
    padding-right: 6rem;
  }
  .xl\:pt-\[65px\] {
    padding-top: 65px;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .xl\:leading-\[1\.2\] {
    line-height: 1.2;
  }
  .xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
  .before\:xl\:block::before {
    content: var(--tw-content);
    display: block;
  }
  .before\:xl\:rounded-\[0_0\.75rem_0\.75rem_0\/0_1\.1rem_1\.1rem_0\]::before {
    content: var(--tw-content);
    border-radius: 0 0.75rem 0.75rem 0/0 1.1rem 1.1rem 0;
  }
  .after\:xl\:-mr-4::after {
    content: var(--tw-content);
    margin-right: -1rem;
  }
  .after\:xl\:block::after {
    content: var(--tw-content);
    display: block;
  }
  .after\:xl\:hidden::after {
    content: var(--tw-content);
    display: none;
  }
  .after\:xl\:rounded-\[1\.2rem\/1\.7rem\]::after {
    content: var(--tw-content);
    border-radius: 1.2rem/1.7rem;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:ml-0 {
    margin-left: 0px;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:ml-4 {
    margin-left: 1rem;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:ml-6 {
    margin-left: 1.5rem;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:ml-8 {
    margin-left: 2rem;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:ml-\[90px\] {
    margin-left: 90px;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:w-\[275px\] {
    width: 275px;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:w-\[100px\] {
    width: 100px;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:w-\[91px\] {
    width: 91px;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:-rotate-180 {
    --tw-rotate: -180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:rotate-180 {
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:border-solid {
    border-style: solid;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:border-slate-100 {
    --tw-border-opacity: 1;
    border-color: rgb(241 245 249/var(--tw-border-opacity));
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:border-slate-50 {
    --tw-border-opacity: 1;
    border-color: rgb(248 250 252/var(--tw-border-opacity));
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:bg-slate-50 {
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252/var(--tw-bg-opacity));
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:pl-\[100px\] {
    padding-left: 100px;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:pl-\[91px\] {
    padding-left: 91px;
  }
  .group.mode--light .xl\:group-\[\.mode--light\]\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:opacity-100 {
    opacity: 1;
  }
  .group.side-menu--collapsed .group-\[\.side-menu--collapsed\]\:xl\:opacity-0 {
    opacity: 0;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:shadow-\[6px_0_12px_-4px_\#0000000f\] {
    --tw-shadow: 6px 0 12px -4px #0000000f;
    --tw-shadow-colored: 6px 0 12px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:shadow-\[6px_0_12px_-4px_\#0000001f\] {
    --tw-shadow: 6px 0 12px -4px #0000001f;
    --tw-shadow-colored: 6px 0 12px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
  .group.side-menu--collapsed.side-menu--on-hover .before\:group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:hidden::before {
    content: var(--tw-content);
    display: none;
  }
  .group.side-menu--collapsed.side-menu--on-hover .before\:group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:h-full::before {
    content: var(--tw-content);
    height: 100%;
  }
  .group.side-menu--collapsed.side-menu--on-hover .before\:group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:border-solid::before {
    content: var(--tw-content);
    border-style: solid;
  }
  .group.side-menu--collapsed.side-menu--on-hover .group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:before\:bg-white::before {
    content: var(--tw-content);
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
  }
  .group.side-menu--collapsed.side-menu--on-hover .before\:group-\[\.side-menu--collapsed\.side-menu--on-hover\]\:xl\:shadow-\[6px_0_12px_-4px_\#0000000f\]::before {
    content: var(--tw-content);
    --tw-shadow: 6px 0 12px -4px #0000000f;
    --tw-shadow-colored: 6px 0 12px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }
}
@media (min-width: 1536px) {
  .\32 xl\:col-span-12 {
    grid-column: span 12/span 12;
  }
  .\32 xl\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .\32 xl\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .\32 xl\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .\32 xl\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .\32 xl\:col-span-7 {
    grid-column: span 7/span 7;
  }
  .\32 xl\:col-span-8 {
    grid-column: span 8/span 8;
  }
  .\32 xl\:col-span-9 {
    grid-column: span 9/span 9;
  }
  .\32 xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .\32 xl\:ml-3 {
    margin-left: 0.75rem;
  }
  .\32 xl\:ml-36 {
    margin-left: 9rem;
  }
  .\32 xl\:ml-auto {
    margin-left: auto;
  }
  .\32 xl\:mr-0 {
    margin-right: 0px;
  }
  .\32 xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .\32 xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .\32 xl\:h-11 {
    height: 2.75rem;
  }
  .\32 xl\:w-11 {
    width: 2.75rem;
  }
  .\32 xl\:w-auto {
    width: auto;
  }
  .\32 xl\:max-w-\[1750px\] {
    max-width: 1750px;
  }
  .\32 xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .\32 xl\:flex-row {
    flex-direction: row;
  }
  .\32 xl\:items-center {
    align-items: center;
  }
  .group.mode--light .\32 xl\:group-\[\.mode--light\]\:text-current {
    color: currentColor;
  }
  .group.mode--light .\32 xl\:group-\[\.mode--light\]\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
  }
}
@media (min-width: 1600px) {
  .\33 xl\:flex {
    display: flex;
  }
}
.\[\&\.active\]\:grid.active {
  display: grid;
}

.\[\&\.active\]\:border.active {
  border-width: 1px;
}

.\[\&\.active\]\:border-2.active {
  border-width: 2px;
}

.\[\&\.active\]\:border-primary\/10.active {
  border-color: rgb(var(--color-primary)/0.1);
}

.\[\&\.active\]\:border-primary\/50.active {
  border-color: rgb(var(--color-primary)/0.5);
}

.\[\&\.active\]\:border-slate-200.active {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240/var(--tw-border-opacity));
}

.\[\&\.active\]\:border-theme-1\/20.active {
  border-color: rgb(var(--color-theme-1)/0.2);
}

.\[\&\.active\]\:border-theme-1\/60.active {
  border-color: rgb(var(--color-theme-1)/0.6);
}

.\[\&\.active\]\:border-b-primary.active {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.\[\&\.active\]\:border-b-transparent.active {
  border-bottom-color: transparent;
}

.\[\&\.active\]\:bg-primary\/5.active {
  background-color: rgb(var(--color-primary)/0.05);
}

.\[\&\.active\]\:bg-primary\/90.active {
  background-color: rgb(var(--color-primary)/0.9);
}

.\[\&\.active\]\:bg-primary\/\[0\.04\].active {
  background-color: rgb(var(--color-primary)/0.04);
}

.\[\&\.active\]\:bg-theme-1\/30.active {
  background-color: rgb(var(--color-theme-1)/0.3);
}

.\[\&\.active\]\:bg-white.active {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.\[\&\.active\]\:bg-gradient-to-b.active {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.\[\&\.active\]\:from-theme-1\/70.active {
  --tw-gradient-from: rgb(var(--color-theme-1) / 0.7) var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(var(--color-theme-1) / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.\[\&\.active\]\:to-theme-2\/70.active {
  --tw-gradient-to: rgb(var(--color-theme-2) / 0.7) var(--tw-gradient-to-position);
}

.\[\&\.active\]\:fill-pending\/10.active {
  fill: rgb(var(--color-pending)/0.1);
}

.\[\&\.active\]\:font-medium.active {
  font-weight: 500;
}

.\[\&\.active\]\:text-pending\/80.active {
  color: rgb(var(--color-pending)/0.8);
}

.\[\&\.active\]\:text-primary.active {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.\[\&\.active\]\:text-slate-700.active {
  --tw-text-opacity: 1;
  color: rgb(51 65 85/var(--tw-text-opacity));
}

.\[\&\.active\]\:text-white.active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.\[\&\.active\]\:shadow-sm.active {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.\[\&\.active\]\:before\:bg-primary\/70.active::before {
  content: var(--tw-content);
  background-color: rgb(var(--color-primary)/0.7);
}

.\[\&\.active\]\:dark\:border-darkmode-400:is(.dark *).active {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-400)/var(--tw-border-opacity));
}

.\[\&\.active\]\:dark\:border-x-darkmode-400:is(.dark *).active {
  --tw-border-opacity: 1;
  border-left-color: rgb(var(--color-darkmode-400)/var(--tw-border-opacity));
  border-right-color: rgb(var(--color-darkmode-400)/var(--tw-border-opacity));
}

.\[\&\.active\]\:dark\:border-b-darkmode-600:is(.dark *).active {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--color-darkmode-600)/var(--tw-border-opacity));
}

.\[\&\.active\]\:dark\:border-b-primary:is(.dark *).active {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.\[\&\.active\]\:dark\:border-t-darkmode-400:is(.dark *).active {
  --tw-border-opacity: 1;
  border-top-color: rgb(var(--color-darkmode-400)/var(--tw-border-opacity));
}

.\[\&\.active\]\:dark\:bg-darkmode-400:is(.dark *).active {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-400)/var(--tw-bg-opacity));
}

.\[\&\.active\]\:dark\:bg-transparent:is(.dark *).active {
  background-color: transparent;
}

.\[\&\.active\]\:dark\:text-slate-300:is(.dark *).active {
  --tw-text-opacity: 1;
  color: rgb(203 213 225/var(--tw-text-opacity));
}

.\[\&\.active\]\:dark\:text-white:is(.dark *).active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.\[\&\.background--hidden\]\:before\:from-slate-100.background--hidden::before {
  content: var(--tw-content);
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.\[\&\.background--hidden\]\:before\:to-transparent.background--hidden::before {
  content: var(--tw-content);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.\[\&\.background--hidden\]\:before\:opacity-0.background--hidden::before {
  content: var(--tw-content);
  opacity: 0;
}

.\[\&\.background--hidden\]\:after\:opacity-0.background--hidden::after {
  content: var(--tw-content);
  opacity: 0;
}

.\[\&\.close-mobile-menu--mobile-menu-open\]\:flex.close-mobile-menu--mobile-menu-open {
  display: flex;
}

.\[\&\.collapse\.show\]\:visible.collapse.show {
  visibility: visible;
}

.\[\&\.collapse\:not\(\.show\)\]\:hidden.collapse:not(.show) {
  display: none;
}

@media (min-width: 1280px) {
  .\[\&\.content--compact\]\:xl\:ml-\[100px\].content--compact {
    margin-left: 100px;
  }
  .\[\&\.content--compact\]\:xl\:ml-\[91px\].content--compact {
    margin-left: 91px;
  }
}
.\[\&\.dropzone\]\:cursor-pointer.dropzone {
  cursor: pointer;
}

.\[\&\.dropzone\]\:border-2.dropzone {
  border-width: 2px;
}

.\[\&\.dropzone\]\:border-dashed.dropzone {
  border-style: dashed;
}

.\[\&\.dropzone\]\:border-slate-300\/70.dropzone {
  border-color: rgba(203, 213, 225, 0.7);
}

.\[\&\.dropzone\]\:bg-slate-50.dropzone {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.\[\&\.dropzone\]\:dark\:border-white\/5:is(.dark *).dropzone {
  border-color: rgba(255, 255, 255, 0.05);
}

.\[\&\.dropzone\]\:dark\:bg-darkmode-600:is(.dark *).dropzone {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-600)/var(--tw-bg-opacity));
}

.\[\&\.has-error_\.form-control\]\:border-danger.has-error .form-control {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-danger)/var(--tw-border-opacity));
}

.\[\&\.hide\]\:h-0.hide {
  height: 0px;
}

.\[\&\.hide\]\:overflow-hidden.hide {
  overflow: hidden;
}

.\[\&\.hljs\]\:bg-slate-50.hljs {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252/var(--tw-bg-opacity));
}

.\[\&\.hljs\]\:px-5.hljs {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.\[\&\.hljs\]\:py-4.hljs {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.\[\&\.hljs\]\:dark\:bg-darkmode-700:is(.dark *).hljs {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-700)/var(--tw-bg-opacity));
}

.\[\&\.hljs\]\:dark\:text-slate-200:is(.dark *).hljs {
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity));
}

.\[\&\.hljs_\.hljs-attr\]\:dark\:text-sky-500:is(.dark *).hljs .hljs-attr {
  --tw-text-opacity: 1;
  color: rgb(14 165 233/var(--tw-text-opacity));
}

.\[\&\.hljs_\.hljs-name\]\:dark\:text-emerald-500:is(.dark *).hljs .hljs-name {
  --tw-text-opacity: 1;
  color: rgb(16 185 129/var(--tw-text-opacity));
}

.\[\&\.hljs_\.hljs-string\]\:dark\:text-slate-200:is(.dark *).hljs .hljs-string {
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity));
}

.\[\&\.hljs_\.hljs-tag\]\:dark\:text-slate-200:is(.dark *).hljs .hljs-tag {
  --tw-text-opacity: 1;
  color: rgb(226 232 240/var(--tw-text-opacity));
}

.\[\&\.info\]\:border-info\/10.info {
  border-color: rgb(var(--color-info)/0.1);
}

.\[\&\.info\]\:bg-info\/10.info {
  background-color: rgb(var(--color-info)/0.1);
}

.\[\&\.info\]\:text-info.info {
  --tw-text-opacity: 1;
  color: rgb(var(--color-info)/var(--tw-text-opacity));
}

.\[\&\.javascript\]\:before\:content-\[\'JS\'\].javascript::before {
  --tw-content: "JS";
  content: var(--tw-content);
}

.\[\&\.loading-page--before-hide\]\:before\:block.loading-page--before-hide::before {
  content: var(--tw-content);
  display: block;
}

.\[\&\.loading-page--before-hide\]\:after\:block.loading-page--before-hide::after {
  content: var(--tw-content);
  display: block;
}

.\[\&\.loading-page--hide\]\:before\:opacity-0.loading-page--hide::before {
  content: var(--tw-content);
  opacity: 0;
}

.\[\&\.loading-page--hide\]\:after\:opacity-0.loading-page--hide::after {
  content: var(--tw-content);
  opacity: 0;
}

.\[\&\.pending\]\:border-pending\/10.pending {
  border-color: rgb(var(--color-pending)/0.1);
}

.\[\&\.pending\]\:bg-pending\/10.pending {
  background-color: rgb(var(--color-pending)/0.1);
}

.\[\&\.pending\]\:text-pending.pending {
  --tw-text-opacity: 1;
  color: rgb(var(--color-pending)/var(--tw-text-opacity));
}

.\[\&\.primary\]\:border-primary\/10.primary {
  border-color: rgb(var(--color-primary)/0.1);
}

.\[\&\.primary\]\:bg-primary\/10.primary {
  background-color: rgb(var(--color-primary)/0.1);
}

.\[\&\.primary\]\:text-primary.primary {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.\[\&\.right\]\:ml-auto.right {
  margin-left: auto;
}

.\[\&\.right\]\:mr-0.right {
  margin-right: 0px;
}

.\[\&\.right\]\:flex-row-reverse.right {
  flex-direction: row-reverse;
}

.\[\&\.right\]\:rounded-l-xl.right {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.\[\&\.right\]\:rounded-tr-none.right {
  border-top-right-radius: 0px;
}

.\[\&\.right\]\:text-right.right {
  text-align: right;
}

.\[\&\.show\]\:visible.show {
  visibility: visible;
}

.\[\&\.show\]\:opacity-100.show {
  opacity: 1;
}

.\[\&\.show\]\:duration-\[0s\,0\.1s\].show {
  transition-duration: 0s, 0.1s;
}

.\[\&\.show\]\:duration-\[0s\,0\.4s\].show {
  transition-duration: 0s, 0.4s;
}

.\[\&\.side-menu--mobile-menu-open\]\:ml-0.side-menu--mobile-menu-open {
  margin-left: 0px;
}

.\[\&\.side-menu--mobile-menu-open\]\:after\:block.side-menu--mobile-menu-open::after {
  content: var(--tw-content);
  display: block;
}

.\[\&\.success\]\:border-success\/10.success {
  border-color: rgb(var(--color-success)/0.1);
}

.\[\&\.success\]\:bg-success\/10.success {
  background-color: rgb(var(--color-success)/0.1);
}

.\[\&\.success\]\:text-success.success {
  --tw-text-opacity: 1;
  color: rgb(var(--color-success)/var(--tw-text-opacity));
}

.\[\&\.warning\]\:border-warning\/10.warning {
  border-color: rgb(var(--color-warning)/0.1);
}

.\[\&\.warning\]\:bg-warning\/10.warning {
  background-color: rgb(var(--color-warning)/0.1);
}

.\[\&\.warning\]\:text-warning.warning {
  --tw-text-opacity: 1;
  color: rgb(var(--color-warning)/var(--tw-text-opacity));
}

.\[\&\:-webkit-scrollbar\]\:w-0:-webkit-scrollbar {
  width: 0px;
}

.\[\&\:-webkit-scrollbar\]\:bg-transparent:-webkit-scrollbar {
  background-color: transparent;
}

.\[\&\:disabled\:checked\]\:cursor-not-allowed:disabled:checked {
  cursor: not-allowed;
}

.\[\&\:disabled\:checked\]\:opacity-70:disabled:checked {
  opacity: 0.7;
}

.\[\&\:disabled\:checked\]\:dark\:bg-darkmode-800\/50:is(.dark *):disabled:checked {
  background-color: rgb(var(--color-darkmode-800)/0.5);
}

.\[\&\:disabled\:not\(\:checked\)\]\:cursor-not-allowed:disabled:not(:checked) {
  cursor: not-allowed;
}

.\[\&\:disabled\:not\(\:checked\)\]\:bg-slate-100:disabled:not(:checked) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.\[\&\:disabled\:not\(\:checked\)\]\:dark\:bg-darkmode-800\/50:is(.dark *):disabled:not(:checked) {
  background-color: rgb(var(--color-darkmode-800)/0.5);
}

.\[\&\:hover\:not\(\:disabled\)\]\:border-slate-100:hover:not(:disabled) {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249/var(--tw-border-opacity));
}

.\[\&\:hover\:not\(\:disabled\)\]\:border-opacity-10:hover:not(:disabled) {
  --tw-border-opacity: 0.1;
}

.\[\&\:hover\:not\(\:disabled\)\]\:border-opacity-90:hover:not(:disabled) {
  --tw-border-opacity: 0.9;
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-danger\/10:hover:not(:disabled) {
  background-color: rgb(var(--color-danger)/0.1);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-darkmode-800\/30:hover:not(:disabled) {
  background-color: rgb(var(--color-darkmode-800)/0.3);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-pending\/10:hover:not(:disabled) {
  background-color: rgb(var(--color-pending)/0.1);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-primary\/10:hover:not(:disabled) {
  background-color: rgb(var(--color-primary)/0.1);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-secondary\/20:hover:not(:disabled) {
  background-color: rgb(var(--color-secondary)/0.2);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-slate-100:hover:not(:disabled) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-success\/10:hover:not(:disabled) {
  background-color: rgb(var(--color-success)/0.1);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-warning\/10:hover:not(:disabled) {
  background-color: rgb(var(--color-warning)/0.1);
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-opacity-10:hover:not(:disabled) {
  --tw-bg-opacity: 0.1;
}

.\[\&\:hover\:not\(\:disabled\)\]\:bg-opacity-90:hover:not(:disabled) {
  --tw-bg-opacity: 0.9;
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-darkmode-100\/20:is(.dark *):hover:not(:disabled) {
  border-color: rgb(var(--color-darkmode-100)/0.2);
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-darkmode-300\/80:is(.dark *):hover:not(:disabled) {
  border-color: rgb(var(--color-darkmode-300)/0.8);
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-darkmode-800:is(.dark *):hover:not(:disabled) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-800)/var(--tw-border-opacity));
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:border-opacity-60:is(.dark *):hover:not(:disabled) {
  --tw-border-opacity: 0.6;
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-darkmode-100\/10:is(.dark *):hover:not(:disabled) {
  background-color: rgb(var(--color-darkmode-100)/0.1);
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-darkmode-300\/80:is(.dark *):hover:not(:disabled) {
  background-color: rgb(var(--color-darkmode-300)/0.8);
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-darkmode-800\/50:is(.dark *):hover:not(:disabled) {
  background-color: rgb(var(--color-darkmode-800)/0.5);
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:dark\:bg-darkmode-800\/70:is(.dark *):is(.dark *):hover:not(:disabled) {
  background-color: rgb(var(--color-darkmode-800)/0.7);
}

.\[\&\:hover\:not\(\:disabled\)\]\:dark\:bg-opacity-30:is(.dark *):hover:not(:disabled) {
  --tw-bg-opacity: 0.3;
}

.\[\&\:hover_svg\]\:rotate-180:hover svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.\[\&\:hover_td\]\:bg-slate-100:hover td {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.\[\&\:hover_td\]\:dark\:bg-darkmode-300:is(.dark *):hover td {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-300)/var(--tw-bg-opacity));
}

.\[\&\:hover_td\]\:dark\:bg-opacity-50:is(.dark *):hover td {
  --tw-bg-opacity: 0.5;
}

.\[\&\:not\(\.active\)\]\:hover\:bg-slate-100:hover:not(.active) {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.\[\&\:not\(\.active\)\]\:dark\:hover\:border-transparent:hover:is(.dark *):not(.active) {
  border-color: transparent;
}

.\[\&\:not\(\.active\)\]\:dark\:hover\:bg-darkmode-400:hover:is(.dark *):not(.active) {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-400)/var(--tw-bg-opacity));
}

.\[\&\:not\(\.active\)_a\]\:hidden:not(.active) a {
  display: none;
}

.\[\&\:not\(\.collapsed\)\]\:text-primary:not(.collapsed) {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.\[\&\:not\(\.collapsed\)\]\:dark\:text-slate-300:is(.dark *):not(.collapsed) {
  --tw-text-opacity: 1;
  color: rgb(203 213 225/var(--tw-text-opacity));
}

.\[\&\:not\(\.show\)\]\:invisible:not(.show) {
  visibility: hidden;
}

.\[\&\:not\(\.show\)\]\:opacity-0:not(.show) {
  opacity: 0;
}

.\[\&\:not\(\.show\)\]\:delay-\[0\.2s\,0s\]:not(.show) {
  transition-delay: 0.2s, 0s;
}

.\[\&\:not\(\.show\)\]\:duration-\[0s\,0\.2s\]:not(.show) {
  transition-duration: 0s, 0.2s;
}

.\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:-mt-px:not(:first-child):not(:last-child) {
  margin-top: -1px;
}

.\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:rounded-none:not(:first-child):not(:last-child) {
  border-radius: 0px;
}

@media (min-width: 768px) {
  .\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:md\:-ml-px:not(:first-child):not(:last-child) {
    margin-left: -1px;
  }
  .\[\&\:not\(\:first-child\)\:not\(\:last-child\)\]\:md\:mt-0:not(:first-child):not(:last-child) {
    margin-top: 0px;
  }
}
.group.input-group .group-\[\.input-group\]\:\[\&\:not\(\:first-child\)\]\:border-l-transparent:not(:first-child) {
  border-left-color: transparent;
}

.\[\&\:not\(\:last-child\)\]\:border-b:not(:last-child) {
  border-bottom-width: 1px;
}

.\[\&\:not\(\:last-child\)\]\:border-slate-200\/60:not(:last-child) {
  border-color: rgba(226, 232, 240, 0.6);
}

.\[\&\:not\(\:last-child\)\]\:dark\:border-darkmode-400:is(.dark *):not(:last-child) {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-darkmode-400)/var(--tw-border-opacity));
}

.\[\&\:not\(button\)\]\:text-center:not(button) {
  text-align: center;
}

.\[\&\:nth-child\(4n\)\]\:border-r-0:nth-child(4n) {
  border-right-width: 0px;
}

.\[\&\:nth-last-child\(-n\+4\)\]\:border-b-0:nth-last-child(-n+4) {
  border-bottom-width: 0px;
}

.\[\&\:nth-of-type\(n\+5\)\]\:hidden:nth-of-type(n+5) {
  display: none;
}

@media (min-width: 1536px) {
  .\[\&\:nth-of-type\(n\+5\)\]\:2xl\:flex:nth-of-type(n+5) {
    display: flex;
  }
}
.\[\&\:nth-of-type\(odd\)_td\]\:bg-slate-100:nth-of-type(odd) td {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.\[\&\:nth-of-type\(odd\)_td\]\:dark\:bg-darkmode-300:is(.dark *):nth-of-type(odd) td {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-darkmode-300)/var(--tw-bg-opacity));
}

.\[\&\:nth-of-type\(odd\)_td\]\:dark\:bg-opacity-50:is(.dark *):nth-of-type(odd) td {
  --tw-bg-opacity: 0.5;
}

.\[\&\[aria-selected\=\'true\'\]_button\]\:border-primary\/\[0\.15\][aria-selected=true] button {
  border-color: rgb(var(--color-primary)/0.15);
}

.\[\&\[aria-selected\=\'true\'\]_button\]\:bg-primary\/\[0\.04\][aria-selected=true] button {
  background-color: rgb(var(--color-primary)/0.04);
}

.\[\&\[aria-selected\=\'true\'\]_button\]\:font-medium[aria-selected=true] button {
  font-weight: 500;
}

.\[\&\[aria-selected\=\'true\'\]_button\]\:text-current[aria-selected=true] button {
  color: currentColor;
}

.\[\&\[aria-selected\=\'true\'\]_button\]\:text-primary[aria-selected=true] button {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary)/var(--tw-text-opacity));
}

.\[\&\[aria-selected\=\'true\'\]_button\]\:shadow-sm[aria-selected=true] button {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.\[\&\[readonly\]\]\:cursor-not-allowed[readonly] {
  cursor: not-allowed;
}

.\[\&\[readonly\]\]\:bg-slate-100[readonly] {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249/var(--tw-bg-opacity));
}

.\[\&\[readonly\]\]\:dark\:border-transparent:is(.dark *)[readonly] {
  border-color: transparent;
}

.\[\&\[readonly\]\]\:dark\:bg-darkmode-800\/50:is(.dark *)[readonly] {
  background-color: rgb(var(--color-darkmode-800)/0.5);
}

.\[\&\[type\=\'checkbox\'\]\]\:checked\:border-primary:checked[type=checkbox] {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.\[\&\[type\=\'checkbox\'\]\]\:checked\:border-opacity-10:checked[type=checkbox] {
  --tw-border-opacity: 0.1;
}

.\[\&\[type\=\'checkbox\'\]\]\:checked\:bg-primary:checked[type=checkbox] {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.\[\&\[type\=\'file\'\]\]\:border[type=file] {
  border-width: 1px;
}

.\[\&\[type\=\'radio\'\]\]\:checked\:border-primary:checked[type=radio] {
  --tw-border-opacity: 1;
  border-color: rgb(var(--color-primary)/var(--tw-border-opacity));
}

.\[\&\[type\=\'radio\'\]\]\:checked\:border-opacity-10:checked[type=radio] {
  --tw-border-opacity: 0.1;
}

.\[\&\[type\=\'radio\'\]\]\:checked\:bg-primary:checked[type=radio] {
  --tw-bg-opacity: 1;
  background-color: rgb(var(--color-primary)/var(--tw-bg-opacity));
}

.\[\&_\.leaflet-tile-pane\]\:saturate-\[\.3\] .leaflet-tile-pane {
  --tw-saturate: saturate(.3);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\[\&_\.simplebar-content\]\:p-0 .simplebar-content {
  padding: 0px;
}

.\[\&_\.simplebar-track\.simplebar-vertical\]\:mr-0\.5 .simplebar-track.simplebar-vertical {
  margin-right: 0.125rem;
}

.\[\&_\.simplebar-track\.simplebar-vertical\]\:w-\[10px\] .simplebar-track.simplebar-vertical {
  width: 10px;
}

.\[\&_\.simplebar-track\.simplebar-vertical_\.simplebar-scrollbar\]\:before\:bg-slate-400\/20 .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  content: var(--tw-content);
  background-color: rgba(148, 163, 184, 0.2);
}

.\[\&_\.simplebar-track\.simplebar-vertical_\.simplebar-scrollbar\]\:before\:bg-slate-400\/30 .simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  content: var(--tw-content);
  background-color: rgba(148, 163, 184, 0.3);
}

.\[\&_\.tns-nav\]\:bottom-auto .tns-nav {
  bottom: auto;
}

.\[\&_\.tns-nav\]\:ml-5 .tns-nav {
  margin-left: 1.25rem;
}

.\[\&_\.tns-nav\]\:mt-5 .tns-nav {
  margin-top: 1.25rem;
}

.\[\&_\.tns-nav\]\:w-auto .tns-nav {
  width: auto;
}

.\[\&_\.tns-nav_button\.tns-nav-active\]\:w-5 .tns-nav button.tns-nav-active {
  width: 1.25rem;
}

.\[\&_\.tns-nav_button\.tns-nav-active\]\:bg-white\/70 .tns-nav button.tns-nav-active {
  background-color: rgba(255, 255, 255, 0.7);
}

.\[\&_\.tns-nav_button\]\:mx-0\.5 .tns-nav button {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.\[\&_\.tns-nav_button\]\:h-2 .tns-nav button {
  height: 0.5rem;
}

.\[\&_\.tns-nav_button\]\:w-2 .tns-nav button {
  width: 0.5rem;
}

.\[\&_\.tns-nav_button\]\:bg-white\/40 .tns-nav button {
  background-color: rgba(255, 255, 255, 0.4);
}

.\[\&_a\]\:shadow-none a {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.\[\&_button\.active\]\:text-current button.active {
  color: currentColor;
}

.group.mode--light .group-\[\.mode--light\]\:\[\&_button\.active\]\:border-transparent button.active {
  border-color: transparent;
}

.group.mode--light .group-\[\.mode--light\]\:\[\&_button\.active\]\:bg-white\/\[0\.12\] button.active {
  background-color: rgba(255, 255, 255, 0.12);
}

.\[\&_div\]\:bg-white div {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.\[\&_div\]\:first\:rounded-l-md:first-child div {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.\[\&_div\]\:first\:border-l:first-child div {
  border-left-width: 1px;
}

.\[\&_div\]\:last\:rounded-r-md:last-child div {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.\[\&_div\]\:last\:border-r:last-child div {
  border-right-width: 1px;
}

.\[\&_img\]\:hover\:h-\[150\%\]:hover img {
  height: 150%;
}

.\[\&_img\]\:hover\:w-\[150\%\]:hover img {
  width: 150%;
}

.\[\&_td\]\:first\:pt-5:first-child td {
  padding-top: 1.25rem;
}

.\[\&_td\]\:last\:border-b-0:last-child td {
  border-bottom-width: 0px;
}

.\[\&_td\]\:last\:pb-5:last-child td {
  padding-bottom: 1.25rem;
}

.group.mode--light .\[\.group\.mode--light_\&\.active\]\:text-white.active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255/var(--tw-text-opacity));
}

.group.mode--light .\[\.group\.mode--light_\&\.active\]\:before\:bg-white.active::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255/var(--tw-bg-opacity));
}

.group.mode--light .group.active .\[\.group\.mode--light_\.group\.active_\&\]\:\!border-white\/\[0\.15\] {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.group.mode--light .group.active .\[\.group\.mode--light_\.group\.active_\&\]\:\!bg-white\/\[0\.12\] {
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.group.mode--light .group.active .\[\.group\.mode--light_\.group\.active_\&\]\:\!text-slate-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(241 245 249/var(--tw-text-opacity)) !important;
}